import { FC, useEffect } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { LoginPage } from './pages/login'
import { AppProvider } from './stores/store'
import { useChangeLocalhostUrl } from './hooks/useChangeLocalhostUrl'
import { useRedirectToLogin } from './hooks/useRedirectToLogin'
import { useShowMaintenanceModal } from './hooks/useShowMaintenanceModal'
import { ThemeProvider } from '@mui/material/styles'
import IndexPage from './pages/index'
import Amplitude from './utils/analytics/amplitude'
import { MedChangePage } from './pages/medChange'
import { useCheckAuth } from './hooks/useCheckAuth'
import { useGetTheme } from './hooks/useGetTheme'
import { devReportRoutes } from './pages/devReport/routes'
import { helpRoutes } from './pages/help/routes'
import { PlaygroundRoutes } from './pages/playground/routes'
import { LegacyRoutes } from './pages/legacy/routes'

Amplitude.init()

const handleDocumentClick = (event: MouseEvent) => {
  const element = event.target as HTMLElement
  if (element.tagName == 'BUTTON' || element.closest('button') != null) {
    Amplitude.logEvent({
      event: 'BUTTON_CLICK',
      properties: {
        tagName: element.tagName,
        id: element.id,
        timestamp: new Date().toISOString(),
        pageUrl: window.location.href,
      },
    })
  }
}

const Pages = () => {
  useRedirectToLogin()
  useCheckAuth()
  const theme = useGetTheme()
  const MaintenanceModal = useShowMaintenanceModal()
  return (
    <>
      <ThemeProvider theme={theme}>
        {MaintenanceModal}
        <Route exact path="/" component={IndexPage} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/med_change" component={MedChangePage} />
        {PlaygroundRoutes.map(({ path, component: Component }) => (
          <Route key={path} exact path={path} component={Component} />
        ))}
        {devReportRoutes.map(({ path, component: Component }) => (
          <Route key={path} exact path={path} component={Component} />
        ))}
        {helpRoutes.map(({ path, component: Component }) => (
          <Route key={path} exact path={path} component={Component} />
        ))}
        {LegacyRoutes.map(({ path, component: Component }) => (
          <Route key={path} exact path={path} component={Component} />
        ))}
      </ThemeProvider>
    </>
  )
}

const App: FC = () => {
  useChangeLocalhostUrl()
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
  }, [])
  return (
    <AppProvider>
      <BrowserRouter>
        <Pages />
      </BrowserRouter>
    </AppProvider>
  )
}

export default App
