import React, { FC } from 'react'
import Markdown from 'markdown-to-jsx'
import {
  Box,
  Button,
  Container,
  Typography,
  useTheme,
  useScrollTrigger,
  Slide,
} from '@mui/material'
import { useHistory } from 'react-router-dom'

// ここではサンプルとして、固定のMarkdown文字列を用意
// 実際には、APIやファイルから取得したMarkdownを流す形にしてもOKです。
const markdownText = `
## Step1: レセコンが用法を認識せず、毎回入れ直しになっている場合

●レセコンの「QR用法マスタ登録」を行うと改善する場合がございます

●「QR用法マスタ登録」の手順については、レセコンベンダー様へお問い合わせください

## Step2: コメントに入っていてほしいものが用法に入っている場合 (もしくはその逆)

●まず、該当の用法とコメントを、まもる君アプリ(今開いているもの)上からタップして報告をください

●その上で、xxx画面から、どのようにしてその用法が入っていて欲しいか送付ください ⇒ こちらに基づき、AI側の設定が更新され、ご用命の出力方式へとアップデートされます


---

今後もFocus on the Userに基づき、薬局の皆様からのお声に耳を傾け、より質の高いサービス開発・提供を目指してまいります。
些細なことでも結構ですので、ご質問・ご要望がございましたら、どうぞお気軽に＜support@medilab.co.jp＞までお寄せください。
`

export const YouhouMatching: FC = () => {
  const theme = useTheme()
  const history = useHistory()

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  })

  return (
    <>
      <Box
        sx={{
          minHeight: '100vh',
          backgroundColor: theme.palette.grey[100],
          py: 4,
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h4" component="h1" gutterBottom>
            用法がうまくレセコンに入らない
          </Typography>
          {/* Markdown の表示 */}
          <Markdown>{markdownText}</Markdown>
        </Container>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: 24,
          left: 40,
          zIndex: 1000,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => history.push('/')}
        >
          {'処方画面へ戻る'}
        </Button>
      </Box>
    </>
  )
}
