import React, { useState, useCallback, FC } from 'react'
import { memo, NamedExoticComponent } from 'react'
import { Prescription } from '../types/models/Presc'
import { Box, Button, Typography, IconButton, Tooltip } from '@mui/material'
import { MenuIcon } from '../icons/MenuIcon'
import { DrawerPrescList } from '../components/organisms/Drawer'
import { Pharmacy } from '../types/models/Pharmacy'
import { PharmacyDrawerList } from '../components/organisms/PharmacyDrawer'
import { usePharmacy } from './usePharmacy'
import { useAnnotatePharmacyDrawerButton } from './useAnnotatePharmacyDrawer'
import { useAnnotatePrescDrawerButton } from './useAnnotatePrescDrawer'
import { SearchBar } from '../components/organisms/SerachBar'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

export const useAnnotate = () => {
  const [copySuccess, setCopySuccess] = useState(false)

  const {
    AnnotatePharmacyDrawerButton,
    selectedPharmacy,
  } = useAnnotatePharmacyDrawerButton()
  const {
    AnnotatePrescDrawerButton,
    selectedPresc,
    loadAnnotatePrescList,
    selectOcrRequest,
    prescriptions,
  } = useAnnotatePrescDrawerButton(selectedPharmacy)

  const AnnotateDrawerButtons = (
    <>
      {AnnotatePharmacyDrawerButton}
      {AnnotatePrescDrawerButton}
    </>
  )

  const request_event_ids = prescriptions.map(
    (presc) => presc.ocr_request_event_id
  )

  // リクエストIDのコピー機能
  const handleCopyRequestId = useCallback(() => {
    if (selectedPresc?.ocr_request_event_id) {
      navigator.clipboard.writeText(selectedPresc.ocr_request_event_id)
      setCopySuccess(true)
      setTimeout(() => setCopySuccess(false), 2000)
    }
  }, [selectedPresc])

  // 検索バーとリクエストID表示を統合したコンポーネント
  const SearchBox = (
    <Box sx={{ px: 0, width: '100%' }}>
      {/* 検索バーのコンテナは固定幅を設定せず、元の挙動を維持 */}
      <Box>
        <SearchBar onClick={selectOcrRequest} options={request_event_ids} />
      </Box>

      {selectedPresc && (
        <Box
          sx={{
            p: 2,
            bgcolor: 'background.paper',
            borderRadius: 1,
            mb: 2,
            ml: 0,
            mr: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid #e0e0e0',
            marginTop: '8px',
            boxSizing: 'border-box',
          }}
        >
          <Typography variant="body2" fontWeight="bold" color="text.primary">
            選択中OCRリクエストID:{' '}
            {selectedPresc?.ocr_request_event_id || 'なし'}
          </Typography>
          <Tooltip title={copySuccess ? 'コピーしました！' : 'IDをコピー'}>
            <IconButton
              size="small"
              onClick={handleCopyRequestId}
              disabled={!selectedPresc?.ocr_request_event_id}
            >
              <ContentCopyIcon
                fontSize="small"
                color={copySuccess ? 'success' : 'action'}
              />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  )

  return {
    AnnotateDrawerButtons,
    selectedPresc,
    loadAnnotatePrescList,
    SearchBox,
  }
}
