import React, { FC } from 'react'
import Markdown from 'markdown-to-jsx'
import { Box, Container, Typography, useTheme } from '@mui/material'

// ここではサンプルとして、固定のMarkdown文字列を用意
// 実際には、APIやファイルから取得したMarkdownを流す形にしてもOKです。
const markdownText = `
# 【mediLab開発チームより】

ユーザ薬局の皆様へ

日頃よりmediLabの調剤入力支援AI「まもる君」、薬歴らくらくAI「kakeru君」をご利用いただき、ありがとうございます。

mediLabでは、ユーザ薬局の皆様により一層お使い頂きやすいサービスを目指し、日々、読み取り精度向上をはじめとするサービスの質改善に努め、開発に取り組んでおります。具体的にどのような項目において精度改善・向上されたか、開発チームによるレポートを定期的にお届けしてまいります。

mediLab 開発チーム一同

---

## ◆調剤入力支援AI「まもる君」

### ●読み取りの基礎性能を向上しました
生年月日・性別等の項目の基礎性能を引き続き向上しました。
これにより特に「再来」において、よりスムーズに読み取り結果をレセプトコンピュータに取り込めるようになりました。
更に同項目や医薬品名・医療機関名の学習向上速度についても改善を行い、より迅速に皆様の入力支援を行えるようになりました。

### ●AIガイド基盤を更新しました
「まもる君」では処方箋の読み取りに加え、各種入力支援を行うためのAIガイドを実施しております。
この度、このAIガイドをより潤沢にするため、本機能提供の基盤を整備し、改めてリリースしました。

### ●処方箋上のQR自動読み込みを強化しました
処方箋上にQRが掲載されている際、OCRを行わずにQR結果をレセコンに流す機能をオープンにしました。
従来もQRの内容は検知をしておりましたが、レセコン連携の円滑性の担保を行うため、推論時の補助情報とする等、クローズドな機能提供にとどめておりました。
今回の更新により、QRコード付き処方箋について、読み込み速度の向上が実現されます。
まもる君がQRの有無を自動判別するので、どのような処方箋もスキャンさえすれば最適な方法で読み込むことが可能となります。

---

## ◆薬歴音声入力AI「Kakeru君」

### ●SOAPの精度向上
Kakeru君のSOAPに含まれるノイズが軽減されました。以前より、患者様の声が小さい等の理由で生じる文字起こしミスにより、SOAPにノイズ発生する課題がありましたが、この度、Kakeru君モデルを更新したことでノイズ軽減に成功いたしました。

### ●文字起こしの性能が改善しました
医薬品名の読み取り精度や患者様の声の読み取り精度が向上いたしました。これまで、一般的に馴染みのない医薬品名や患者様の声が小さい場合などでKakeru君の文字起こし性能が低下するという事象がありましたが、この度、Kakeru君のモデルを更新し、これらの課題を軽減することに成功いたしました。

### ●高速モードを実装しました
Kakeru君に高速モードを導入いたしました。これまで、薬局店内のノイズに対応するため、サイズの大きいモデルを構築して音声文字起こし及びSOAP作成を行ってまいりましたが、かねてより「私の薬局は静かなので、SOAPの精度よりも早くSOAPを作成して欲しい」という要望をいただいておりました。そしてこの度、軽量モデル版のKakeru君を開発・選択可能とし、軽量モデルでは処理速度が50%向上したことをご報告いたします。

---

今後もFocus on the Userに基づき、薬局の皆様からのお声に耳を傾け、より質の高いサービス開発・提供を目指してまいります。
些細なことでも結構ですので、ご質問・ご要望がございましたら、どうぞお気軽に＜support@medilab.co.jp＞までお寄せください。
`

export const DevReport202412: FC = () => {
  const theme = useTheme()

  return (
    <>
      <Box
        sx={{
          minHeight: '100vh',
          backgroundColor: theme.palette.grey[100],
          py: 4,
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h4" component="h1" gutterBottom>
            今月のmediLab開発レポート ＜2024.12月＞
          </Typography>
          {/* markdown-to-jsx でMarkdownをそのまま表示 */}
          <Markdown>{markdownText}</Markdown>
        </Container>
      </Box>
    </>
  )
}
