import { FC } from 'react'
import { Avatar } from '../atoms/Avatar'
import AIAvatar from '../atoms/ai.png'
import { Box, Stack, Typography } from '@mui/material'

type Props = {
  text: string
}

export const AIMessageBalloon: FC<Props> = ({ text }) => {
  const formattedText = text.replace(/\n/g, '<br />')

  return (
    <Stack direction="row" spacing={2}>
      <Avatar url={AIAvatar} />
      <Box
        sx={{
          position: 'relative',
          padding: '10px',
          backgroundColor: 'primary.main',
          width: '60%',
          color: 'primary.contrastText',
          textAlign: 'left',
          border: '1px solid',
          borderColor: 'primary.main',
          borderRadius: '10px',
          '&:after': {
            content: "''",
            position: 'absolute',
            width: '0',
            height: '0',
            borderTop: '17px solid',
            borderColor: 'primary.main',
            borderLeft: '15px solid transparent',
            borderRight: '15px solid transparent',
            top: '0',
            left: '-15px',
          },
          '&:before': {
            content: "''",
            position: 'absolute',
            width: '0',
            height: '0',
            borderTop: '17px solid',
            borderColor: 'primary.main',
            borderLeft: '16px solid transparent',
            borderRight: '16px solid transparent',
            top: '-1px',
            left: '-17px',
          },
        }}
      >
        <Typography
          variant="inherit"
          dangerouslySetInnerHTML={{ __html: formattedText }}
        />
      </Box>
    </Stack>
  )
}
