import {
  Snackbar,
  Stack,
  Box,
  IconButton,
  InputAdornment,
  Modal,
  CircularProgress,
  Typography,
  TextField,
  useTheme,
  Button,
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import MuiAlert from '@mui/material/Alert'
import CloseIcon from '@mui/icons-material/Close'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded'
import { useCallback, useState } from 'react'
import {
  postBasicResultReport,
  postRPUnitReport,
  postMedicationReport,
  postRPIndexReport,
  postMedResultShortage,
} from '../apis/reportPresc'
import { postRequestMessage } from '../apis/requestMessages'
import {
  BasicResult,
  Prescription,
  RPUnit,
  Medication,
} from '../types/models/Presc'
import {
  CustomButton,
  CustomIconButton,
} from '../components/molecules/CustomButton'
import { localStorageWrapper } from '../helpers/localStorage'
import { SHORT_SERVICE_NAMES } from '../constants/const'
import { useHistory } from 'react-router-dom'

type Params = {
  prescription: Prescription
  onSuccess: () => Promise<void>
}

export const useReportPresc = ({ prescription, onSuccess }: Params) => {
  const [isOtherReportModalOpen, setIsOtherReportModalOpen] = useState<boolean>(
    false
  )
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false)
  const [isInputModalOpen, setIsInputModalOpen] = useState<boolean>(false)
  const [isSnackAlertOpen, setIsSnackAlertOpen] = useState<boolean>(false)
  const [isSending, setIsSending] = useState(false)
  const theme = useTheme()
  const [freeInputError, setFreeInputError] = useState<string>('')
  const [questionError, setQuestionError] = useState<string>('')
  const [requestError, setRequestError] = useState<string>('')
  const history = useHistory()

  const handleFreeInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFreeInputError(event.target.value)
  }

  const handleQuestionInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuestionError(event.target.value)
  }

  const handleRequestInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRequestError(event.target.value)
  }

  const handleSubmit = (event: React.MouseEvent) => {
    if (freeInputError) {
      reportInput('自由入力: ' + freeInputError)
    }
    if (questionError) {
      reportInput('不明点: ' + questionError)
    }
    if (requestError) {
      reportInput('要望: ' + requestError)
    }

    setFreeInputError('')
    setQuestionError('')
    setRequestError('')
  }

  const shortServiceName = (() => {
    const app_type = localStorageWrapper.getItem('app_type') || 'Medilab'
    const name =
      app_type === 'TMN' || app_type === 'TMN_BETA'
        ? SHORT_SERVICE_NAMES.TMN
        : SHORT_SERVICE_NAMES.DEFAULT
    return name
  })()

  // Patch
  const inferenceErrorTypes = [
    { type: 'med_shortage', displayLabel: '薬剤に欠損がある' },
    { type: 'med_mistake', displayLabel: '薬剤に誤りがある' },
    { type: 'youhou_mistake', displayLabel: '用法に誤りがある' },
    {
      type: 'chouzai_youryou_mistake',
      displayLabel: '日数・用量に誤りがある',
    },
    {
      type: 'chouzai_youryou_shortage',
      displayLabel: '日数・用量が「0」になっている',
    },
  ]

  const receiptErrorTypes = [
    {
      type: '再来患者が新患と認識される',
      displayLabel: '再来患者が新患と認識される',
      route: '/help/patient_matching',
    },
    {
      type: '医師名がレセコンに認識されない',
      displayLabel: '医師名がレセコンに認識されない',
      route: '/help/doctor_matching',
    },
    {
      type: '用法がうまくレセコンに入らない',
      displayLabel: '用法がうまくレセコンに入らない',
      route: '/help/youhou_matching',
    },
  ]

  const ReportSendingOverlay = isSending && (
    <Stack
      sx={{
        zIndex: 1305,
        position: 'absolute',
        bgcolor: 'rgb(0,0,0, 0.4)',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      spacing={2}
    >
      <CircularProgress style={{ width: '100px', height: '100px' }} />
      <Typography sx={{ color: '#fff', fontSize: '24px' }}>
        報告送信中...
      </Typography>
    </Stack>
  )

  const renderSnackBar = () => (
    <div>
      <Modal
        open={isOtherReportModalOpen}
        onClose={() => setIsOtherReportModalOpen(false)}
      >
        <MuiAlert
          elevation={10}
          variant="filled"
          iconMapping={{
            success: (
              <CampaignRoundedIcon style={{ fontSize: 40, color: 'black' }} />
            ),
          }}
          sx={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '24px',
            padding: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            width: 600,
          }}
          style={{
            backgroundColor: 'white',
            color: 'black',
          }}
        >
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={() => setIsOtherReportModalOpen(false)}
            style={{ position: 'absolute', top: '0', right: '0' }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          <Stack direction="column" alignItems="center">
            <Typography variant="h5" sx={{ pb: 2 }}>
              {'その他' + shortServiceName + 'への報告'}
            </Typography>
            <Stack>
              {inferenceErrorTypes.map((item) => (
                <Stack key={item.type} p={1}>
                  <CustomButton
                    variant="contained"
                    sx={{ width: 400, height: 50 }}
                    onClick={() => reportSpecificError(item.type)}
                  >
                    {item.displayLabel}
                  </CustomButton>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </MuiAlert>
      </Modal>
      <Modal open={isHelpModalOpen} onClose={() => setIsHelpModalOpen(false)}>
        <MuiAlert
          elevation={10}
          variant="filled"
          iconMapping={{
            success: (
              <HelpOutlineIcon style={{ fontSize: 40, color: 'black' }} />
            ),
          }}
          sx={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '24px',
            padding: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            width: 600,
          }}
          style={{
            backgroundColor: 'white',
            color: 'black',
          }}
        >
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={() => setIsHelpModalOpen(false)}
            style={{ position: 'absolute', top: '0', right: '0' }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          <Stack direction="column" alignItems="center">
            <Typography variant="h5" sx={{ pb: 2 }}>
              {'よくあるご質問'}
            </Typography>
            <Stack>
              {receiptErrorTypes.map((item) => (
                <Stack key={item.type} p={1}>
                  <CustomButton
                    variant="contained"
                    sx={{ width: 400, height: 50 }}
                    onClick={() => history.push(item.route)}
                  >
                    {item.displayLabel}
                  </CustomButton>
                </Stack>
              ))}
              <Stack p={1}>
                <CustomButton
                  variant="contained"
                  sx={{ width: 400, height: 50 }}
                  onClick={() => {
                    setIsHelpModalOpen(false)
                    setIsInputModalOpen(true)
                  }}
                >
                  {'その他ご質問'}
                </CustomButton>
              </Stack>
            </Stack>
          </Stack>
        </MuiAlert>
      </Modal>
      <Modal open={isInputModalOpen} onClose={() => setIsInputModalOpen(false)}>
        <MuiAlert
          elevation={10}
          variant="filled"
          iconMapping={{
            success: (
              <HelpOutlineIcon style={{ fontSize: 40, color: 'black' }} />
            ),
          }}
          sx={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '24px',
            padding: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            width: 600,
          }}
          style={{
            backgroundColor: 'white',
            color: 'black',
          }}
        >
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={() => setIsInputModalOpen(false)}
            style={{ position: 'absolute', top: '0', right: '0' }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          <Stack direction="column" alignItems="center">
            <Typography variant="h5" sx={{ pb: 2 }}>
              {'操作方法について'}
            </Typography>
            <Stack p={1}>
              <Stack direction="column" p={1} spacing={4}>
                <TextField
                  sx={{ width: 400, flexGrow: 1 }}
                  label="操作方法について"
                  placeholder="不明点を入力してください"
                  variant="outlined"
                  value={questionError}
                  onChange={handleQuestionInputChange}
                  multiline
                  minRows={2}
                />
                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  sx={{ bgcolor: theme.palette.primary.dark, color: '#fff' }}
                  onClick={handleSubmit}
                >
                  送信
                </Button>
              </Stack>
            </Stack>
            <Stack width="100%" p={4}>
              <Box display="flex" justifyContent="center" width="100%">
                <hr
                  style={{
                    border: 'none',
                    backgroundColor: '#F4F4F4',
                    width: '100%',
                    height: '2px',
                    margin: '0 auto',
                  }}
                />
              </Box>
            </Stack>
            <Typography variant="h5" sx={{ pb: 2 }}>
              {'まもる君への要望'}
            </Typography>
            <Stack p={1}>
              <Stack direction="column" p={1} spacing={4}>
                <TextField
                  sx={{ width: 400, flexGrow: 1 }}
                  label="まもる君への要望"
                  placeholder="ご要望を入力してください"
                  variant="outlined"
                  value={requestError}
                  onChange={handleRequestInputChange}
                  multiline
                  minRows={2}
                />
                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  sx={{ bgcolor: theme.palette.primary.dark, color: '#fff' }}
                  onClick={handleSubmit}
                >
                  送信
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </MuiAlert>
      </Modal>
      <Snackbar
        style={{
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        open={isSnackAlertOpen}
        autoHideDuration={3000}
        onClose={() => setIsSnackAlertOpen(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="success"
          iconMapping={{
            success: <CheckCircleIcon style={{ fontSize: 20 }} />,
          }}
          sx={{
            fontSize: '15px',
            padding: '4px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            width: 300,
            backgroundColor: theme.palette.success.main,
          }}
        >
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={() => setIsSnackAlertOpen(false)}
            style={{ position: 'absolute', top: '0', right: '0' }}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
          {shortServiceName + 'への報告が完了しました。'}
        </MuiAlert>
      </Snackbar>
    </div>
  )

  const reportBasicResult = useCallback(
    async (key: keyof BasicResult, isReported: boolean) => {
      try {
        setIsSending(true)
        await postBasicResultReport({
          prescId: prescription.id,
          key,
          isReported,
        })
        await onSuccess()
        setIsSending(false)
      } catch (e) {
        console.log(e)
      } finally {
        setIsSending(false)
      }
    },
    [prescription, onSuccess, postBasicResultReport]
  )

  const reportRPUnit = useCallback(
    async (rpNum: number, key: keyof RPUnit, isReported: boolean) => {
      try {
        setIsSending(true)
        await postRPUnitReport({
          prescId: prescription.id,
          rpNum,
          key,
          isReported,
        })
        await onSuccess()
        setIsSending(false)
      } catch (e) {
        console.log(e)
      } finally {
        setIsSending(false)
      }
    },
    [prescription, onSuccess, postRPUnitReport]
  )

  const reportMedication = useCallback(
    async (
      rpNum: number,
      rpNumSub: number,
      key: keyof Medication,
      isReported: boolean
    ) => {
      try {
        setIsSending(true)
        await postMedicationReport({
          prescId: prescription.id,
          rpNum,
          rpNumSub,
          key,
          isReported,
        })
        await onSuccess()
        setIsSending(false)
      } catch (e) {
        console.log(e)
      } finally {
        setIsSending(false)
      }
    },
    [prescription, onSuccess, postMedicationReport]
  )

  const reportRPIndex = useCallback(
    async (rpNum: number, isReported: boolean) => {
      try {
        setIsSending(true)
        await postRPIndexReport({
          prescId: prescription.id,
          rpNum,
          isReported,
        })
        await onSuccess()
        setIsSending(false)
      } catch (e) {
        console.log(e)
      } finally {
        setIsSending(false)
      }
    },
    [prescription, onSuccess, postRPIndexReport]
  )

  const reportSpecificError = useCallback(
    async (ocrErrorLabel: string) => {
      try {
        setIsSending(true)
        await postMedResultShortage({
          prescId: prescription.id,
          ocrErrorLabel,
        })
        await onSuccess()
        setIsSending(false)
        setIsOtherReportModalOpen(false)
        setIsHelpModalOpen(false)
        setIsInputModalOpen(false)
        setIsSnackAlertOpen(true)
      } catch (e) {
        console.log(e)
      } finally {
        setIsSending(false)
      }
    },
    [prescription, postMedResultShortage]
  )

  const reportInput = useCallback(
    async (ocrErrorLabel: string) => {
      try {
        setIsSending(true)
        await postRequestMessage(prescription.id, ocrErrorLabel)
        await onSuccess()
        setIsSending(false)
        setIsOtherReportModalOpen(false)
        setIsHelpModalOpen(false)
        setIsInputModalOpen(false)
        setIsSnackAlertOpen(true)
      } catch (e) {
        console.log(e)
      } finally {
        setIsSending(false)
      }
    },
    [prescription, postMedResultShortage]
  )

  // Patch
  const openModal = useCallback(() => {
    onSuccess()
    setIsSending(false)
    const app_type = localStorageWrapper.getItem('app_type') || 'Medilab'

    if (app_type === 'Medilab_LATEST' || app_type === 'Medilab') {
      setIsHelpModalOpen(true)
    } else {
      setIsOtherReportModalOpen(true)
    }
  }, [onSuccess, setIsHelpModalOpen, setIsOtherReportModalOpen])

  return {
    reportBasicResult,
    reportRPUnit,
    reportMedication,
    reportRPIndex,
    openModal,
    renderSnackBar,
    ReportSendingOverlay,
  }
}
