import React, { FC, useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { ButtonBase, Stack, TextField, Typography } from '@mui/material'
import { CSSProperties } from 'react'
import { keyframes } from '@emotion/react'
import { BASIC_RESULT_LABEL } from '../../constants/const'
import { Clipboard } from '../templates/Clipboard'
import { localStorageWrapper } from '../../helpers/localStorage'

type Props = {
  label: string
  value: string
  maxWidth?: CSSProperties['maxWidth']
  hasHighCheckPriority?: boolean
  onClick: () => void
  isHighlighted: boolean
  isShrinkForbidden?: boolean
  isTall?: boolean
}

const StyledInputBox = styled(TextField)<{ isTall: boolean }>(({ isTall }) => ({
  '& .MuiOutlinedInput-root': {
    height: isTall ? '48px' : '32px',
    borderRadius: '0',
  },
  width: '100%',
  '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
    transform: isTall ? 'translate(20%, 45%)' : 'translate(20%, 20%)',
  },
}))

export const HighlightInputBox: FC<Props> = ({
  label,
  value,
  hasHighCheckPriority,
  maxWidth,
  onClick,
  isHighlighted,
  isShrinkForbidden = false,
  isTall = false,
}) => {
  const theme = useTheme()
  const inputBoxColor = isHighlighted
    ? theme.palette.primary.dark
    : theme.palette.secondary.main

  const isNameAbleToCopy = localStorageWrapper.getItem('is_name_able_to_copy')

  return (
    <Stack direction="row" spacing={1}>
      <ButtonBase style={{ width: '100%', maxWidth: maxWidth }}>
        <StyledInputBox
          isTall={isTall}
          label={isShrinkForbidden && value ? '' : label}
          value={value}
          onClick={onClick}
          style={{
            maxWidth: maxWidth,
            backgroundColor: hasHighCheckPriority
              ? theme.palette.warning.light
              : '#fff',
          }}
          InputProps={{
            endAdornment: isHighlighted && <Typography>✔️</Typography>,
            readOnly: true,
          }}
          InputLabelProps={{
            sx: {
              color: inputBoxColor,
              '&.Mui-focused': {
                color: inputBoxColor,
              },
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: inputBoxColor,
                borderWidth: isHighlighted ? '2px' : '1px',
              },
            },
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& > fieldset': {
                borderColor: inputBoxColor,
              },
            },
          }}
        />
      </ButtonBase>
      {isNameAbleToCopy &&
        BASIC_RESULT_LABEL['id_11_patient_name_kana'] === label && (
          <Clipboard value={value} />
        )}
    </Stack>
  )
}
