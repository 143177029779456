import React, { useCallback, useState } from 'react'
import { editPresc } from '../apis/editPresc'
import { BasicResult, Medication, Prescription } from '../types/models/Presc'

type Params = {
  prescription: Prescription
  refreshPrescriptions: () => Promise<void>
}

type EditBasicResultParams = {
  key: keyof BasicResult
  value: string
}

type EditRPnumberItemParams = {
  rpIndex: number
  key: 'RP番号' | '用法名称' | '調剤数量'
  value: string
}

type EditMedResultParams = {
  rpIndex: number
  medIndex: number
  key: keyof Medication
  value: string
}

export const useEditPresc = ({
  prescription,
  refreshPrescriptions,
}: Params) => {
  const [updatingPrescription, setUpdatingPrescription] = useState(false)

  const editBasicResult = useCallback(
    async ({ key, value }: EditBasicResultParams) => {
      try {
        const newPrescription = { ...prescription }
        newPrescription.basic_result[key]['selected_option'] = value
        setUpdatingPrescription(true)
        await editPresc(key, newPrescription)
        console.log('QR変更成功')
        await refreshPrescriptions()
        // TODO:toastで明示
      } catch (e) {
        console.log(e)
        console.log('QR変更失敗')
        // TODO:toastで明示
      } finally {
        setUpdatingPrescription(false)
      }
    },
    [prescription]
  )

  const editRPnumberItem = useCallback(
    async ({ rpIndex, key, value }: EditRPnumberItemParams) => {
      try {
        const newPrescription = { ...prescription }
        newPrescription.med_result[rpIndex][key]['selected_option'] = value
        setUpdatingPrescription(true)
        await editPresc(key, newPrescription)
        console.log('QR変更成功')
        await refreshPrescriptions()
        // TODO:toastで明示
      } catch (e) {
        console.log(e)
        console.log('QR変更失敗')
        // TODO:toastで明示
      } finally {
        setUpdatingPrescription(false)
      }
    },
    [prescription]
  )

  const editMedResult = useCallback(
    async ({ rpIndex, medIndex, key, value }: EditMedResultParams) => {
      try {
        const newPrescription = { ...prescription }
        newPrescription.med_result[rpIndex]['med_result'][medIndex][key][
          'selected_option'
        ] = value
        setUpdatingPrescription(true)
        await editPresc(key, newPrescription)
        console.log('QR変更成功')
        await refreshPrescriptions()
        // TODO:toastで明示
      } catch (e) {
        console.log(e)
        console.log('QR変更失敗')
        // TODO:toastで明示
      } finally {
        setUpdatingPrescription(false)
      }
    },
    [prescription]
  )

  return {
    updatingPrescription,
    editBasicResult,
    editRPnumberItem,
    editMedResult,
  }
}
