import React, { FC, useState, useEffect } from 'react'
import { Box, Container, Typography, useTheme } from '@mui/material'
import Markdown from 'markdown-to-jsx'

export const MusicPage: FC = () => {
  const theme = useTheme()

  // ラップの歌詞
  const rapLyrics = `
webからの資料請求数が堅調<br>
数字は #bot_mail_お客様サポート 参照<br>

Yo! Check it! 見せつけるリアルスキル<br>
毎日磨いて高めるドリル<br>
競合相手 眼中になし マジで<br>
俺らのサイト 検索の頂点（てっぺん）に<br>

SEOの技術 ストリートで磨いた<br>
キーワード選定 誰も真似できない<br>
クリック率 右に上がり<br>
アクセス数 天井知らず ブチ上げ<br>

webからの資料請求数が堅調<br>
数字は #bot_mail_お客様サポート 参照<br>

CVR急上昇 売上げ加速<br>
レスポンシブサイト 全デバイス完璧<br>
UI洗練 UX革新 <br>
離脱率ゼロに向け前進<br>
アナリティクス見れば一目瞭然<br>
数字は嘘つかない 事実を直視<br>

SNS支配 チームの意気<br>
フォロワー増加 止まらぬ勢い<br>
エンゲージ率 常にトップ維持<br>
他社の模倣 決して許さない<br>

webからの資料請求数が堅調<br>
数字は #bot_mail_お客様サポート 参照<br>

リスティング広告の効率化<br>
最小投資で最大収益化<br>
ROIはプラス 収益性抜群<br>
データ判断 常に的確マジ鋭い<br>

A/Bテスト繰り返し 常に前進<br>
ランディングページ 日々進化深化<br>
メールマーケ開封率 業界一番<br>
平均数値 軽く超えてる 認めな<br>

webからの資料請求数が堅調<br>
数字は #bot_mail_お客様サポート 参照<br>

オートメーション完備 効率化<br>
リソース集中 価値の最大化<br>
顧客体験 質を追求<br>
NPS指標も最高評価<br>

デジタルシフト 時代の潮流<br>
古い手法は全て一新<br>
常に先頭 走り続ける<br>
地位は不動 ゆるぎなき証明<br>

webマーケの力 見せつける毎日<br>
実績で語る 俺らの日々<br>
成長曲線 上昇一直線<br>
チーム一丸 最強タッグ結成<br>

webからの資料請求数が堅調<br>
数字は #bot_mail_お客様サポート 参照`

  // ビートプレイヤーコンポーネント
  const ChillRapBeatPlayer: FC = () => {
    const [isPlaying, setIsPlaying] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [tempo, setTempo] = useState(85)
    const [beatStyle, setBeatStyle] = useState('hiphop')
    const [audioContext, setAudioContext] = useState<AudioContext | null>(null)
    const [audioBuffer, setAudioBuffer] = useState<AudioBuffer | null>(null)
    const [
      audioSource,
      setAudioSource,
    ] = useState<AudioBufferSourceNode | null>(null)
    const [loadingStatus, setLoadingStatus] = useState('')

    // テンポに基づいた再生レートを計算
    const getPlaybackRate = () => {
      return tempo / 85 // 85BPMが基準
    }

    // コンポーネントがマウントされたときにオーディオを初期化
    useEffect(() => {
      setLoadingStatus('初期化中...')

      // オーディオコンテキストを作成
      try {
        // TypeScriptでの型定義問題を回避
        const AudioContextClass =
          window.AudioContext || (window as any).webkitAudioContext || null

        if (AudioContextClass) {
          const context = new AudioContextClass()
          setAudioContext(context)
          setIsLoaded(true)
          setLoadingStatus('準備完了！')
        } else {
          throw new Error('AudioContext not supported')
        }
      } catch (error) {
        console.error('オーディオコンテキストの作成に失敗しました:', error)
        setLoadingStatus(
          'オーディオの初期化に失敗しました。ブラウザを更新して再試行してください。'
        )
      }

      // クリーンアップ関数
      return () => {
        if (audioSource) {
          audioSource.stop()
        }
        if (audioContext) {
          audioContext.close()
        }
      }
    }, [])

    // ドラムビートを生成して再生する関数
    const generateDrumBeat = async () => {
      if (!audioContext) return null

      setLoadingStatus('ビート生成中...')

      // 既存のオーディオソースを停止
      if (audioSource) {
        audioSource.stop()
        setAudioSource(null)
      }

      try {
        // ビートの長さ（秒）
        const beatLength = 4 // 4秒のループ
        const sampleRate = audioContext.sampleRate
        const frameCount = sampleRate * beatLength

        // 新しいオーディオバッファを作成
        const buffer = audioContext.createBuffer(2, frameCount, sampleRate)
        const leftChannel = buffer.getChannelData(0)
        const rightChannel = buffer.getChannelData(1)

        // キック、スネア、ハイハットのタイミングを定義（秒単位）
        const kickTimes = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5]
        const snareTimes = [1, 3]
        const hihatTimes = []

        // 16分音符ごとにハイハットを配置
        for (let i = 0; i < 16; i++) {
          hihatTimes.push(i * 0.25)
        }

        // ベースノートのタイミング
        const bassTimes = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5]

        // スタイルに基づいてビートパターンを調整
        if (beatStyle === 'hiphop') {
          // キックをチルなヒップホップ風に（少し間引く）
          kickTimes.length = 0
          ;[0, 1.25, 2, 3.5].forEach((time) => kickTimes.push(time))

          // スネアをヒップホップ風に
          snareTimes.length = 0
          ;[1, 3].forEach((time) => snareTimes.push(time))

          // ハイハットもよりチルに（間引く）
          hihatTimes.length = 0
          for (let i = 0; i < 8; i++) {
            hihatTimes.push(i * 0.5) // 8分音符ベースに
          }
        } else if (beatStyle === 'corporate') {
          // コーポレートスタイル（よりシンプルで控えめ）
          kickTimes.length = 0
          ;[0, 2].forEach((time) => kickTimes.push(time)) // 1拍目と3拍目だけ

          // スネアをよりシンプルに
          snareTimes.length = 0
          ;[1, 3].forEach((time) => snareTimes.push(time))

          // ハイハットを減らす
          hihatTimes.length = 0
          for (let i = 0; i < 4; i++) {
            hihatTimes.push(i) // 4分音符ベースに
          }
        }

        // サンプルを生成して配置
        // キックドラム
        kickTimes.forEach((time) => {
          const startSample = Math.floor(time * sampleRate)
          const kickLength = Math.floor(0.15 * sampleRate) // 150ms

          for (let i = 0; i < kickLength; i++) {
            if (i < kickLength) {
              // エンベロープ（減衰する波形）
              const amplitude = Math.exp(-i / (kickLength * 0.2)) * 0.8
              // 周波数が徐々に下がる（ピッチベンド）
              const frequency = 120 * Math.exp(-i / (kickLength * 0.5))
              const sample =
                amplitude * Math.sin((2 * Math.PI * frequency * i) / sampleRate)

              if (startSample + i < frameCount) {
                leftChannel[startSample + i] += sample
                rightChannel[startSample + i] += sample
              }
            }
          }
        })

        // スネアドラム
        snareTimes.forEach((time) => {
          const startSample = Math.floor(time * sampleRate)
          const snareLength = Math.floor(0.1 * sampleRate) // 100ms

          for (let i = 0; i < snareLength; i++) {
            // エンベロープ
            const amplitude = Math.exp(-i / (snareLength * 0.2)) * 0.6
            // ノイズ成分
            const noise = (Math.random() * 2 - 1) * amplitude
            // トーン成分
            const tone =
              Math.sin((2 * Math.PI * 180 * i) / sampleRate) * amplitude * 0.7
            const sample = noise + tone

            if (startSample + i < frameCount) {
              leftChannel[startSample + i] += sample
              rightChannel[startSample + i] += sample
            }
          }
        })

        // ハイハット
        hihatTimes.forEach((time) => {
          const startSample = Math.floor(time * sampleRate)
          const hatLength = Math.floor(0.05 * sampleRate) // 50ms

          for (let i = 0; i < hatLength; i++) {
            // 高速減衰するノイズ
            const amplitude = Math.exp(-i / (hatLength * 0.1)) * 0.3
            const noise = (Math.random() * 2 - 1) * amplitude

            if (startSample + i < frameCount) {
              leftChannel[startSample + i] += noise
              rightChannel[startSample + i] += noise
            }
          }
        })

        // ベース音
        bassTimes.forEach((time, index) => {
          const startSample = Math.floor(time * sampleRate)
          const baseLength = Math.floor(0.25 * sampleRate) // 250ms

          // ベースラインを変化させる
          const baseFrequencies = [60, 65, 55, 50] // 異なる音階
          const freq = baseFrequencies[index % baseFrequencies.length]

          for (let i = 0; i < baseLength; i++) {
            // エンベロープ（緩やかな減衰）
            const amplitude = 0.6 * Math.exp(-i / (baseLength * 0.8))
            const sample =
              amplitude * Math.sin((2 * Math.PI * freq * i) / sampleRate)

            if (startSample + i < frameCount) {
              leftChannel[startSample + i] += sample * 0.6 // ベースは少し左に寄せる
              rightChannel[startSample + i] += sample * 0.4 // ベースは少し左に寄せる
            }
          }
        })

        // チルなパーカッション要素を追加

        // ボンゴ/コンガのようなパーカッション
        const congaTimes = [0.25, 1.25, 2.25, 3.25] // オフビートに配置
        congaTimes.forEach((time) => {
          const startSample = Math.floor(time * sampleRate)
          const congaLength = Math.floor(0.1 * sampleRate) // 100ms

          for (let i = 0; i < congaLength; i++) {
            // エンベロープ（急速に減衰）
            const amplitude = Math.exp(-i / (congaLength * 0.2)) * 0.3
            // やや高めの周波数（ボンゴ/コンガっぽく）
            const frequency = 200
            const sample =
              amplitude * Math.sin((2 * Math.PI * frequency * i) / sampleRate)

            if (startSample + i < frameCount) {
              leftChannel[startSample + i] += sample * 0.7 // 右に寄せる
              rightChannel[startSample + i] += sample * 0.3 // 右に寄せる
            }
          }
        })

        // シェイカー/マラカスのような要素
        // チル系の楽曲でよく使われる16分音符のシェイカー
        const shakerTimes = []
        for (let i = 0; i < 32; i++) {
          // 32分音符のシェイカーパターン
          shakerTimes.push(i * 0.125)
        }

        shakerTimes.forEach((time) => {
          const startSample = Math.floor(time * sampleRate)
          const shakerLength = Math.floor(0.03 * sampleRate) // 30ms

          for (let i = 0; i < shakerLength; i++) {
            // 非常に短いエンベロープ
            const amplitude = Math.exp(-i / (shakerLength * 0.1)) * 0.15
            // ノイズベース（シェイカーっぽく）
            const noise = (Math.random() * 2 - 1) * amplitude
            // 高音域をエンファシス
            const filteredNoise =
              noise *
              (0.5 + 0.5 * Math.sin((2 * Math.PI * 2000 * i) / sampleRate))

            if (startSample + i < frameCount) {
              leftChannel[startSample + i] += filteredNoise * 0.4 // ステレオ定位
              rightChannel[startSample + i] += filteredNoise * 0.6 // ステレオ定位
            }
          }
        })

        // 木琴/鉄琴的な要素（ローファイヒップホップでよく使われる）
        const chimesTimes = [0.75, 2.75] // 1小節の後半に配置
        const chimesNotes = [400, 500] // 音程

        chimesTimes.forEach((time, index) => {
          const startSample = Math.floor(time * sampleRate)
          const chimesLength = Math.floor(0.5 * sampleRate) // 500ms（長めの余韻）

          for (let i = 0; i < chimesLength; i++) {
            // ゆっくり減衰するエンベロープ
            const amplitude = Math.exp(-i / (chimesLength * 0.5)) * 0.25
            // 音程を変える
            const frequency = chimesNotes[index % chimesNotes.length]
            const sample =
              amplitude * Math.sin((2 * Math.PI * frequency * i) / sampleRate)

            if (startSample + i < frameCount) {
              // ステレオ効果を加える（空間的な広がり）
              const pan = Math.sin((2 * Math.PI * 0.5 * i) / sampleRate) * 0.2
              leftChannel[startSample + i] += sample * (0.5 - pan)
              rightChannel[startSample + i] += sample * (0.5 + pan)
            }
          }
        })

        // ローファイエフェクト（チルなビートによく使われる）
        // 簡易的なローパスフィルターとビットクラッシュ効果を適用
        let prevLeftSample = 0
        let prevRightSample = 0

        for (let i = 0; i < frameCount; i++) {
          // 簡易ローパスフィルター（高音域をカット）
          leftChannel[i] = leftChannel[i] * 0.7 + prevLeftSample * 0.3
          rightChannel[i] = rightChannel[i] * 0.7 + prevRightSample * 0.3

          prevLeftSample = leftChannel[i]
          prevRightSample = rightChannel[i]

          // 軽いビットクラッシュ効果（ローファイ感）
          const bitDepth = 32 // ビット深度を下げる（値が小さいほど効果が強い）
          leftChannel[i] = Math.round(leftChannel[i] * bitDepth) / bitDepth
          rightChannel[i] = Math.round(rightChannel[i] * bitDepth) / bitDepth

          // ソフトクリッピング（過大な値を穏やかに抑える）
          leftChannel[i] = Math.tanh(leftChannel[i])
          rightChannel[i] = Math.tanh(rightChannel[i])
        }

        setAudioBuffer(buffer)

        // バッファを再生
        const source = audioContext.createBufferSource()
        source.buffer = buffer
        source.loop = true
        source.playbackRate.value = getPlaybackRate()
        source.connect(audioContext.destination)
        setAudioSource(source)

        setLoadingStatus('')
        return source
      } catch (error) {
        console.error('ビート生成に失敗しました:', error)
        setLoadingStatus('ビート生成に失敗しました。再試行してください。')
        return null
      }
    }

    // ビートの再生/停止を切り替える
    const togglePlayback = async () => {
      if (audioContext?.state === 'suspended') {
        await audioContext.resume()
      }

      if (!isPlaying) {
        const source = await generateDrumBeat()
        if (source) {
          source.start()
          setIsPlaying(true)
        }
      } else {
        if (audioSource) {
          audioSource.stop()
          setAudioSource(null)
        }
        setIsPlaying(false)
      }
    }

    // テンポを変更する
    const changeTempo = (newTempo: number) => {
      setTempo(newTempo)
      if (audioSource) {
        audioSource.playbackRate.value = newTempo / 80
      }
    }

    // スタイルを変更する
    const changeStyle = async (newStyle: string) => {
      setBeatStyle(newStyle)
      if (isPlaying) {
        // 現在再生中なら、新しいスタイルで再生し直す
        if (audioSource) {
          audioSource.stop()
        }
        const source = await generateDrumBeat()
        if (source) {
          source.start()
        }
      }
    }

    // Claudeスタイルのモダンなデザイン
    return (
      <Box
        sx={{
          p: 4,
          mb: 5,
          maxWidth: '100%',
          mx: 'auto',
          background: 'linear-gradient(135deg, #2D3748 0%, #1A202C 100%)',
          color: 'white',
          borderRadius: '12px',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '3px',
            background: 'linear-gradient(90deg, #805AD5 0%, #6B46C1 100%)',
          },
        }}
      >
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            fontWeight: 700,
            letterSpacing: '0.5px',
            mb: 2,
          }}
        >
          Beat
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <Box
            sx={{
              px: 2,
              py: 0.75,
              background: 'rgba(113, 128, 150, 0.2)',
              color: 'white',
              borderRadius: 20,
              fontSize: '0.8rem',
              display: 'inline-block',
              border: '1px solid rgba(113, 128, 150, 0.3)',
              fontWeight: 500,
              letterSpacing: '0.5px',
            }}
          >
            Lo-fiパーカッションビート
          </Box>
        </Box>

        {loadingStatus && (
          <Box
            sx={{
              mb: 3,
              textAlign: 'center',
              color: '#F6AD55',
              fontSize: '0.875rem',
              py: 1,
              px: 2,
              mx: 'auto',
              maxWidth: '80%',
              borderRadius: 1,
              bgcolor: 'rgba(246, 173, 85, 0.1)',
              border: '1px solid rgba(246, 173, 85, 0.2)',
            }}
          >
            {loadingStatus}
          </Box>
        )}

        <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
          <button
            onClick={togglePlayback}
            disabled={!isLoaded}
            style={{
              padding: '12px 30px',
              background: 'linear-gradient(90deg, #805AD5 0%, #6B46C1 100%)',
              color: 'white',
              border: 'none',
              borderRadius: '30px',
              cursor: 'pointer',
              fontSize: '1rem',
              fontWeight: 600,
              letterSpacing: '0.5px',
              boxShadow: '0 4px 10px rgba(113, 128, 150, 0.3)',
              transition: 'all 0.2s ease',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {isPlaying ? (
              <>
                <span style={{ marginRight: '8px' }}>■</span> 停止
              </>
            ) : (
              <>
                <span style={{ marginRight: '8px' }}>▶</span> 再生
              </>
            )}
          </button>
        </Box>

        <Box
          sx={{
            mb: 4,
            mx: 'auto',
            maxWidth: '90%',
            bgcolor: 'rgba(255, 255, 255, 0.05)',
            p: 3,
            borderRadius: 2,
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Typography
            variant="body2"
            sx={{ mb: 1.5, fontWeight: 500, color: 'rgba(255, 255, 255, 0.8)' }}
          >
            テンポ: {tempo} BPM
          </Typography>
          <Box
            sx={{
              position: 'relative',
              height: '24px', // 高さを増やして操作しやすく
              bgcolor: 'rgba(255, 255, 255, 0.1)',
              borderRadius: '12px',
              display: 'flex',
              alignItems: 'center',
              padding: '0 2px',
            }}
          >
            <input
              type="range"
              min="65"
              max="95"
              value={tempo}
              onChange={(e) => changeTempo(parseInt(e.target.value))}
              style={{
                width: '100%',
                height: '100%',
                appearance: 'none',
                WebkitAppearance: 'none',
                background: 'transparent',
                position: 'relative',
                zIndex: 10,
                cursor: 'pointer',
                margin: 0,
                padding: 0,
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: `${((tempo - 65) / 30) * 100}%`,
                maxWidth: 'calc(100% - 4px)',
                background: 'linear-gradient(90deg, #805AD5 0%, #6B46C1 100%)',
                borderRadius: '12px',
                transition: 'width 0.2s ease',
                pointerEvents: 'none',
              }}
            />
          </Box>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography
            variant="body2"
            sx={{
              mb: 1.5,
              fontWeight: 500,
              color: 'rgba(255, 255, 255, 0.8)',
              fontSize: '0.9rem',
              position: 'relative',
              zIndex: 5,
              marginBottom: '10px',
            }}
          >
            スタイル
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
            <button
              onClick={() => changeStyle('corporate')}
              style={{
                padding: '10px 20px',
                background:
                  beatStyle === 'corporate'
                    ? 'linear-gradient(90deg, #805AD5 0%, #6B46C1 100%)'
                    : 'rgba(255, 255, 255, 0.05)',
                color: 'white',
                border:
                  beatStyle === 'corporate'
                    ? 'none'
                    : '1px solid rgba(255, 255, 255, 0.2)',
                borderRadius: '8px',
                fontSize: '0.875rem',
                fontWeight: 500,
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                minWidth: '120px',
              }}
            >
              コーポレート
            </button>
            <button
              onClick={() => changeStyle('hiphop')}
              style={{
                padding: '10px 20px',
                background:
                  beatStyle === 'hiphop'
                    ? 'linear-gradient(90deg, #805AD5 0%, #6B46C1 100%)'
                    : 'rgba(255, 255, 255, 0.05)',
                color: 'white',
                border:
                  beatStyle === 'hiphop'
                    ? 'none'
                    : '1px solid rgba(255, 255, 255, 0.2)',
                borderRadius: '8px',
                fontSize: '0.875rem',
                fontWeight: 500,
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                minWidth: '120px',
              }}
            >
              ヒップホップ
            </button>
          </Box>
        </Box>

        <Box
          sx={{
            fontSize: '0.75rem',
            color: 'rgba(255, 255, 255, 0.6)',
            mt: 3,
            pt: 3,
            borderTop: '1px solid rgba(255, 255, 255, 0.1)',
            textAlign: 'center',
            lineHeight: 1.6,
          }}
        ></Box>
      </Box>
    )
  }

  return (
    <>
      <Box
        sx={{
          minHeight: '100vh',
          backgroundColor: theme.palette.grey[100],
          py: 4,
        }}
      >
        <Container maxWidth="md">
          {/* ラップビートプレイヤーを追加 */}
          <ChillRapBeatPlayer />

          {/* ラップ歌詞を表示 */}
          <Box
            sx={{
              p: 3,
              mb: 4,
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: 1,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Rap
            </Typography>
            <Markdown>{rapLyrics}</Markdown>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default MusicPage
