import React, { FC } from 'react'
import Markdown from 'markdown-to-jsx'
import { Box, Container, Typography, useTheme } from '@mui/material'

// ここではサンプルとして、固定のMarkdown文字列を用意
// 実際には、APIやファイルから取得したMarkdownを流す形にしてもOKです。
const markdownText = `
# 【mediLab開発チームより】

ユーザ薬局の皆様へ

日頃よりmediLabの調剤入力支援AI「まもる君」、薬歴らくらくAI「kakeru君」をご利用いただき、ありがとうございます。

mediLabでは、ユーザ薬局の皆様により一層お使い頂きやすいサービスを目指し、日々、読み取り精度向上をはじめとするサービスの質改善に努め、開発に取り組んでおります。具体的にどのような項目において精度改善・向上されたか、開発チームによるレポートを定期的にお届けしてまいります。

mediLab 開発チーム一同

---

## ◆調剤入力支援AI「まもる君」

### ●基礎性能の向上
保険情報・生年月日を中心に、引き続き読み取り性能向上が確認されました。

### ●学習速度の向上
まもる君のモデル学習スピードが向上し、よりスムーズに精度向上できるようになりました。

### ●読み取り精度に関するアラートの強化
元画像が粗く読み取り不足の可能性がある場合など、当該内容をより迅速に入力中のスタッフに連携できるようになりました。

---

## ◆薬歴らくらくAI「Kakeru君」

### ●SOAP表現の調整
SOAPの項目において特にAの項目が会話情報から類推できていない。できていても深い洞察ではないといった課題がありました。<br>
この事象についてAIに学習させ改善いたしました。


### ●NSIPSとの連携を開始
Kakeru君は過去処方等の情報を参照することができないといった課題がありましたが、この度Kakeru君はNSIPSとの連携を正式に開始いたしました。<br>
今後、過去処方や入力確定情報を元に更なる機能開発を行なっていく予定でございます。

### ●患者情報の検索性向上
患者情報のラベル付けにより、検索性が向上しました。

---

今後もFocus on the Userに基づき、薬局の皆様からのお声に耳を傾け、より質の高いサービス開発・提供を目指してまいります。
些細なことでも結構ですので、ご質問・ご要望がございましたら、どうぞお気軽に＜support@medilab.co.jp＞までお寄せください。
`

export const DevReport202501: FC = () => {
  const theme = useTheme()

  return (
    <>
      <Box
        sx={{
          minHeight: '100vh',
          backgroundColor: theme.palette.grey[100],
          py: 4,
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h4" component="h1" gutterBottom>
            今月のmediLab開発レポート ＜2025.1月＞
          </Typography>
          {/* markdown-to-jsx でMarkdownをそのまま表示 */}
          <Markdown>{markdownText}</Markdown>
        </Container>
      </Box>
    </>
  )
}
