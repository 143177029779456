import { FC } from 'react'
import { DevReport202412 } from './202412'
import { DevReport202501 } from './202501'

type DevReportRoute = {
  path: string
  component: FC
}
export const devReportRoutes: DevReportRoute[] = [
  { path: '/release/dev-202412', component: DevReport202412 },
  { path: '/release/dev-202501', component: DevReport202501 },
]
