import { FC } from 'react'
import { Dashboard } from './dashboard'
import { MusicPage } from './musicPlay'

type PlaygroundRoute = {
  path: string
  component: FC
}
export const PlaygroundRoutes: PlaygroundRoute[] = [
  { path: '/playground/dashboard', component: Dashboard },
  { path: '/playground/music', component: MusicPage },
]
