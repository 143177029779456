import React, { FC, useState } from 'react'
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { PatientMessageBalloon } from '../../components/molecules/PatientMessageBalloon'
import { AIMessageBalloon } from '../../components/molecules/AIMessageBalloon'
import { ChatBox } from '../../components/molecules/ChatBox'
import { useEffect } from 'react'
import { Message } from '../../types/models/Message'
import useReactRouter from 'use-react-router'
import { SendingOverlay } from '../../components/molecules/MessageSendingOverlay'

export const AIChatPage: FC = () => {
  const [isSending, setIsSending] = useState<boolean>(false)
  const [text, setText] = useState<string>('')
  const [messages, setMessages] = useState<Message[]>()
  const { history } = useReactRouter()
  const theme = useTheme()

  useEffect(() => {
    fetchMessages()
  }, [])

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const scrollToBottom = () => {
    const scrollerInner = document.getElementById('scroller')
    scrollerInner?.scrollIntoView(false)
  }

  const fetchMessages = async () => {
    try {
      // const { data } = await getMessages()
      // setMessages(data)
    } catch (e) {
      console.log(e)
    }
  }

  const sendMessage = async () => {
    try {
      setIsSending(true)
      setText('')
      // await postMessage(text)
      // await fetchMessages()
    } catch (e) {
      console.log(e)
    } finally {
      setIsSending(false)
    }
  }

  return (
    <div>
      {isSending && SendingOverlay}
      <Box
        sx={{
          position: 'absolute',
          backgroundColor: theme.palette.background.default,
          fontFamily: 'Hiragino Maru Gothic Pro',
          color: theme.palette.text.primary,
          height: '100%',
          width: '100%',
        }}
      >
        <CssBaseline />
        <AppBar position="sticky" color="inherit">
          <Stack p={2} direction="row" justifyContent="space-between">
            <Button
              sx={{ lineHeight: 1 }}
              variant="contained"
              onClick={() => history.push('/')}
            >
              戻る
            </Button>
            <Typography sx={{ lineHeight: 1 }} fontSize={24}>
              まもる君学習画面
            </Typography>
          </Stack>
        </AppBar>
        <Box
          p={2}
          sx={{
            flexGrow: 1,
            height: '80vh',
            overflow: 'scroll',
            paddingBottom: '20vh',
          }}
        >
          <Stack id="scroller" spacing={2}>
            {messages?.map((item) => (
              <Stack key={item.id} sx={{ pl: '12px', pr: '32px' }}>
                {item.is_ai_message ? (
                  <Box>
                    <AIMessageBalloon text={item.text} />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <PatientMessageBalloon text={item.text} />
                  </Box>
                )}
              </Stack>
            ))}
          </Stack>
        </Box>

        <Box
          p={2}
          sx={{
            width: '100%',
            backgroundColor: '#FFFFFF',
            bottom: 0,
            zIndex: 1000,
            position: 'absolute',
            boxShadow: 3,
          }}
        >
          <Stack direction="row" sx={{ display: 'flex', width: '100%' }}>
            <Box width="80%">
              <ChatBox
                value={text}
                onChange={setText}
                size="small"
                label="メッセージを入力"
              />
            </Box>
            <Box pl={2} width="20%">
              <Button fullWidth variant="contained" onClick={sendMessage}>
                送信
              </Button>
            </Box>
          </Stack>
        </Box>
      </Box>
    </div>
  )
}
