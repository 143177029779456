import React, {
  useState,
  FC,
  memo,
  NamedExoticComponent,
  useCallback,
} from 'react'
import { Stack, Box, Button, useTheme, Snackbar } from '@mui/material'
import {
  PRESCRIPTION_INFO,
  INPUTBOX_MAX_WIDTH,
  EDITABLE_ITEM_KEYS,
  REPORT_CHECKBOXES_ITEM_KEYS,
  CHECKBOX_ITEM_KEYS,
  NON_EDITABLE_ITEM_KEYS,
  EDITABLE_ITEM_WITH_SELECT_BOX_KEYS,
  BASIC_RESULT_LABEL,
  DISPLAY_ITEMS,
  CHECKBOX_MAX_WIDTH,
} from '../../constants/const'
import { addIsSelectedProp } from '../../helpers/addIsSelectedPresc'
import {
  EditBasicResultKey,
  BasicResult,
  InputBoxKey,
  CheckBoxKey,
  DisplayItemKey,
  Prescription,
} from '../../types/models/Presc'
import { ReportCheckboxes, ReportCheckBox } from '../molecules/Checkbox'
import { useKanaEditModal } from '../../hooks/useKanaEditModal'
import { useInsuranceNumberEditModal } from '../../hooks/useInsuranceNumberEditModal'
import { useBirthdaySelectModal } from '../../hooks/useBirthdaySelectModal'
import { useGenerateCsvButton } from '../../hooks/useGenerateCsvButton'
import { localStorageWrapper } from '../../helpers/localStorage'
import { HighlightInputBox } from '../molecules/HighlightInputBox'
import { SelectBox } from '../molecules/SelectBox'
import { EditableInputBox } from '../molecules/EditableInputBox'
import { QRImage } from '../molecules/QRImage'

type BasicResultProps = {
  editBasicResult: (key: EditBasicResultKey) => void
  reportBasicResult: (key: keyof BasicResult, isReported: boolean) => void
  basicResult: BasicResult
  prescId: string
  isCheckboxDisabled: boolean
  getHasHighCheckPriority: (user_check_priority: number) => boolean
  displayItems: DisplayItemKey[]
  inTwoColumns: boolean
}

type BasicResultWindowProps = {
  editBasicResult: (key: EditBasicResultKey) => void
  reportBasicResult: (key: keyof BasicResult, isReported: boolean) => void
  selectedPresc: Prescription
  isCheckboxDisabled: boolean
  openQrImageModal: () => void
  getHasHighCheckPriority: (user_check_priority: number) => boolean
}

export type EditableItemKey =
  | 'id_11_patient_name_kana'
  | 'id_22_insurance_patient_num'
  | 'id_13_patient_birthday'

type Props = {
  editBasicResult: (key: EditBasicResultKey) => void
  reportBasicResult: (key: keyof BasicResult, isReported: boolean) => void
  basicResult: BasicResult
  prescId: string
  getHasHighCheckPriority: (user_check_priority: number) => boolean
  isCheckboxDisabled: boolean
  isMainBasicResultShown: boolean
}

const isEditable = (key: EditableItemKey) =>
  key === 'id_11_patient_name_kana'
    ? localStorageWrapper.getItem('isPatientKanaEditable')
    : key === 'id_22_insurance_patient_num'
    ? localStorageWrapper.getItem('isInsuranceNumEditable')
    : localStorageWrapper.getItem('isBirthdayEditable')

const BasicResultMenu: FC<BasicResultProps> = ({
  editBasicResult,
  reportBasicResult,
  basicResult,
  prescId,
  isCheckboxDisabled,
  getHasHighCheckPriority,
  displayItems,
  inTwoColumns,
}) => {
  const {
    openEditableModal: openKanaEditableModal,
    renderEditableModal: renderKanaEditableModal,
  } = useKanaEditModal(prescId, basicResult, editBasicResult)

  const {
    openEditableModal: openInsuranceNumEditableModal,
    renderEditableModal: renderInsuranceNumEditableModal,
  } = useInsuranceNumberEditModal(prescId, basicResult, editBasicResult)

  const {
    openEditableModal: openBirthdaySelectModal,
    renderEditableModal: renderBirthdaySelectModal,
  } = useBirthdaySelectModal(prescId, basicResult, editBasicResult)

  const editBasicResultFactory = useCallback(
    (key: keyof BasicResult) => (value: string) => {
      editBasicResult({
        key,
        value,
      })
    },
    [editBasicResult]
  )

  const openEditableModal = useCallback(
    (key: InputBoxKey) => {
      console.log('key', key)
      return key === 'id_11_patient_name_kana'
        ? openKanaEditableModal
        : key === 'id_22_insurance_patient_num'
        ? openInsuranceNumEditableModal
        : openBirthdaySelectModal
    },
    [
      openKanaEditableModal,
      openInsuranceNumEditableModal,
      openBirthdaySelectModal,
    ]
  )

  const getCheckBoxList = useCallback(
    (key: CheckBoxKey) => {
      return key === 'id_12_patient_sex'
        ? addIsSelectedProp(key, PRESCRIPTION_INFO.SEX_LIST, basicResult)
        : addIsSelectedProp(key, PRESCRIPTION_INFO.INSURANCE_LIST, basicResult)
    },
    [basicResult]
  )

  const makeEditableChoices = useCallback(
    (key: InputBoxKey) => {
      const editableChoice = [
        basicResult[key].selected_option,
        ...basicResult[key].options,
        '入力する',
      ]
      return editableChoice
    },
    [basicResult]
  )
  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: inTwoColumns ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)',
    gap: '12px',
  }

  return (
    <div style={gridStyle}>
      {displayItems.map((item) => (
        <div key={item}>
          {EDITABLE_ITEM_KEYS.includes(item) &&
            (isEditable(item as EditableItemKey) ? (
              <EditableInputBox
                onClick={
                  isEditable(item as EditableItemKey)
                    ? openEditableModal(item as InputBoxKey)
                    : () =>
                        reportBasicResult(item, !basicResult[item].is_reported)
                }
                label={BASIC_RESULT_LABEL[item]}
                isHighlighted={basicResult[item].is_reported}
                value={basicResult[item].selected_option}
                maxWidth={INPUTBOX_MAX_WIDTH.patientInfo}
                hasHighCheckPriority={getHasHighCheckPriority(
                  basicResult[item].user_check_priority
                )}
              />
            ) : (
              <HighlightInputBox
                onClick={
                  isEditable(item as EditableItemKey)
                    ? openEditableModal(item as InputBoxKey)
                    : () =>
                        reportBasicResult(item, !basicResult[item].is_reported)
                }
                label={BASIC_RESULT_LABEL[item]}
                isHighlighted={basicResult[item].is_reported}
                value={basicResult[item].selected_option}
                maxWidth={INPUTBOX_MAX_WIDTH.patientInfo}
                hasHighCheckPriority={getHasHighCheckPriority(
                  basicResult[item].user_check_priority
                )}
              />
            ))}
          {EDITABLE_ITEM_WITH_SELECT_BOX_KEYS.includes(item) &&
            (isEditable(item as EditableItemKey) ? (
              <EditableInputBox
                onClick={
                  isEditable(item as EditableItemKey)
                    ? openEditableModal(item as InputBoxKey)
                    : () =>
                        reportBasicResult(item, !basicResult[item].is_reported)
                }
                label={BASIC_RESULT_LABEL[item]}
                isHighlighted={basicResult[item].is_reported}
                value={basicResult[item].selected_option}
                maxWidth={INPUTBOX_MAX_WIDTH.patientInfo}
                hasHighCheckPriority={getHasHighCheckPriority(
                  basicResult[item].user_check_priority
                )}
              />
            ) : (
              <HighlightInputBox
                onClick={
                  isEditable(item as EditableItemKey)
                    ? openEditableModal(item as InputBoxKey)
                    : () =>
                        reportBasicResult(item, !basicResult[item].is_reported)
                }
                label={BASIC_RESULT_LABEL[item]}
                isHighlighted={basicResult[item].is_reported}
                value={basicResult[item].selected_option}
                maxWidth={INPUTBOX_MAX_WIDTH.patientInfo}
                hasHighCheckPriority={getHasHighCheckPriority(
                  basicResult[item].user_check_priority
                )}
              />
            ))}
          {NON_EDITABLE_ITEM_KEYS.includes(item) && (
            <HighlightInputBox
              onClick={() =>
                reportBasicResult(item, !basicResult[item].is_reported)
              }
              label={BASIC_RESULT_LABEL[item]}
              isHighlighted={basicResult[item].is_reported}
              value={basicResult[item].selected_option}
              maxWidth={INPUTBOX_MAX_WIDTH.patientInfo}
              hasHighCheckPriority={getHasHighCheckPriority(
                basicResult[item].user_check_priority
              )}
            />
          )}
          {REPORT_CHECKBOXES_ITEM_KEYS.includes(item) && (
            <ReportCheckboxes
              onChange={editBasicResultFactory(item)}
              label={BASIC_RESULT_LABEL[item]}
              checkboxList={getCheckBoxList(item as CheckBoxKey)}
              isDisabled={isCheckboxDisabled}
              maxWidth={CHECKBOX_MAX_WIDTH.patientInfo}
              hasHighCheckPriority={getHasHighCheckPriority(
                basicResult[item].user_check_priority
              )}
              isReported={basicResult[item].is_reported}
            />
          )}
          {CHECKBOX_ITEM_KEYS.includes(item) && (
            <ReportCheckBox
              label={BASIC_RESULT_LABEL[item]}
              isChecked={basicResult[item].is_reported}
              onChange={() =>
                reportBasicResult(item, !basicResult[item].is_reported)
              }
            />
          )}
        </div>
      ))}

      {renderKanaEditableModal()}
      {renderInsuranceNumEditableModal()}
      {renderBirthdaySelectModal()}
    </div>
  )
}

const tabBoxProps = (isSelected: boolean) => {
  const theme = useTheme()

  const tintColor = theme.palette.secondary.sub
  const baseColor = theme.palette.secondary.main
  return {
    sx: {
      bgcolor: isSelected ? tintColor : baseColor,
    },
    width: '50%',
    color: '#fff',
    borderRadius: '8px 8px 0px 0px',
    textAlign: 'center' as const,
  }
}

export const PatientMatchingBasicResultWindow: FC<BasicResultWindowProps> = ({
  editBasicResult,
  reportBasicResult,
  selectedPresc,
  isCheckboxDisabled,
  openQrImageModal,
  getHasHighCheckPriority,
}) => {
  const PatientMatchingBasicResultTab = (
    <Stack direction="row">
      <Box {...tabBoxProps(true)}>{'患者一致情報'}</Box>
    </Stack>
  )

  const patientMatchingDisplayItems = (() => {
    const displayItem =
      localStorageWrapper.getItem('patientMatchingDisplayItems') ||
      DISPLAY_ITEMS
    return displayItem
  })()

  const CsvButton = useGenerateCsvButton(selectedPresc?.id)
  return (
    <Box>
      {PatientMatchingBasicResultTab}
      <Stack
        p={1}
        bgcolor="#fff"
        borderRadius="0px 0px 8px 8px"
        direction="row"
        alignItems="flex-start"
      >
        <Stack
          sx={{ boxSizing: 'border-box' }}
          spacing={2}
          p={1}
          width="50%"
          display="inline-block"
        >
          <BasicResultMenu
            prescId={selectedPresc.id}
            reportBasicResult={reportBasicResult}
            editBasicResult={editBasicResult}
            basicResult={selectedPresc.basic_result}
            isCheckboxDisabled={isCheckboxDisabled}
            getHasHighCheckPriority={getHasHighCheckPriority}
            displayItems={patientMatchingDisplayItems}
            inTwoColumns={false}
          />
        </Stack>
        <Stack
          sx={{ boxSizing: 'border-box' }}
          p={1}
          width="50%"
          display="inline-block"
        >
          <QRImage
            prescription={selectedPresc}
            isLoadingNewQr={isCheckboxDisabled}
            openModal={openQrImageModal}
          />
          {CsvButton}
        </Stack>
      </Stack>
    </Box>
  )
}

// 新UI
export const NonMatchingBasicResultWindowWithOtherTab: FC<BasicResultWindowProps> = ({
  editBasicResult,
  reportBasicResult,
  selectedPresc,
  isCheckboxDisabled,
  openQrImageModal,
  getHasHighCheckPriority,
}) => {
  const [mainBasicResultShown, setMainBasicResultShown] = useState(true)
  const [subBasicResultShown, setSubBasicResultShown] = useState(false)
  const NonMatchingBasicResultTab = (
    <Stack direction="row">
      <Box
        {...tabBoxProps(mainBasicResultShown)}
        onClick={() => {
          setMainBasicResultShown(true)
          setSubBasicResultShown(false)
        }}
      >
        {'基本情報'}
      </Box>
      <Box
        {...tabBoxProps(subBasicResultShown)}
        onClick={() => {
          setMainBasicResultShown(false)
          setSubBasicResultShown(true)
        }}
      >
        {'保険情報'}
      </Box>
      <Box
        {...tabBoxProps(!mainBasicResultShown && !subBasicResultShown)}
        onClick={() => {
          setMainBasicResultShown(false)
          setSubBasicResultShown(false)
        }}
      >
        {'その他報告'}
      </Box>
    </Stack>
  )

  const nonMatchingMainDisplayItems = (() => {
    const displayItem =
      localStorageWrapper.getItem('nonMatchingMainDisplayItems') ||
      DISPLAY_ITEMS
    return displayItem
  })()

  const nonMatchingSubDisplayItems = (() => {
    const displayItem =
      localStorageWrapper.getItem('nonMatchingSubDisplayItems') || DISPLAY_ITEMS
    return displayItem
  })()

  const nonMatchingOtherDisplayItems = (() => {
    const displayItem =
      localStorageWrapper.getItem('nonMatchingOtherDisplayItems') ||
      DISPLAY_ITEMS
    return displayItem
  })()

  return (
    <Box>
      {NonMatchingBasicResultTab}
      <Stack
        p={1}
        bgcolor="#fff"
        borderRadius="0px 0px 8px 8px"
        direction="row"
        alignItems="flex-start"
      >
        <Stack
          sx={{ boxSizing: 'border-box' }}
          spacing={2}
          p={1}
          width="100%"
          display="inline-block"
        >
          {mainBasicResultShown ? (
            <BasicResultMenu
              prescId={selectedPresc.id}
              reportBasicResult={reportBasicResult}
              editBasicResult={editBasicResult}
              basicResult={selectedPresc.basic_result}
              isCheckboxDisabled={isCheckboxDisabled}
              getHasHighCheckPriority={getHasHighCheckPriority}
              displayItems={nonMatchingMainDisplayItems}
              inTwoColumns={true}
            />
          ) : subBasicResultShown ? (
            <BasicResultMenu
              prescId={selectedPresc.id}
              reportBasicResult={reportBasicResult}
              editBasicResult={editBasicResult}
              basicResult={selectedPresc.basic_result}
              isCheckboxDisabled={isCheckboxDisabled}
              getHasHighCheckPriority={getHasHighCheckPriority}
              displayItems={nonMatchingSubDisplayItems}
              inTwoColumns={true}
            />
          ) : (
            <BasicResultMenu
              prescId={selectedPresc.id}
              reportBasicResult={reportBasicResult}
              editBasicResult={editBasicResult}
              basicResult={selectedPresc.basic_result}
              isCheckboxDisabled={isCheckboxDisabled}
              getHasHighCheckPriority={getHasHighCheckPriority}
              displayItems={nonMatchingOtherDisplayItems}
              inTwoColumns={true}
            />
          )}
        </Stack>
      </Stack>
    </Box>
  )
}

// RXクラウド用Patch
export const MergedBasicResultWindow: FC<BasicResultWindowProps> = ({
  editBasicResult,
  reportBasicResult,
  selectedPresc,
  isCheckboxDisabled,
  openQrImageModal,
  getHasHighCheckPriority,
}) => {
  const [mainBasicResultShown, setMainBasicResultShown] = useState(true)
  const BasicResultTab = (
    <Stack direction="row">
      <Box
        {...tabBoxProps(mainBasicResultShown)}
        onClick={() => setMainBasicResultShown(true)}
      >
        {'基本患者情報'}
      </Box>
      <Box
        {...tabBoxProps(!mainBasicResultShown)}
        onClick={() => setMainBasicResultShown(false)}
      >
        {'その他患者情報'}
      </Box>
    </Stack>
  )

  const patientMatchingDisplayItems = (() => {
    const displayItem =
      localStorageWrapper.getItem('patientMatchingDisplayItems') ||
      DISPLAY_ITEMS
    return displayItem
  })()

  const nonMatchingMainDisplayItems = (() => {
    const displayItem =
      localStorageWrapper.getItem('nonMatchingMainDisplayItems') ||
      DISPLAY_ITEMS
    return displayItem
  })()

  const nonMatchingSubDisplayItems = (() => {
    const displayItem =
      localStorageWrapper.getItem('nonMatchingSubDisplayItems') || DISPLAY_ITEMS
    return displayItem
  })()

  const CsvButton = useGenerateCsvButton(selectedPresc?.id)
  return (
    <Box>
      {BasicResultTab}
      <Stack
        p={1}
        bgcolor="#fff"
        borderRadius="0px 0px 8px 8px"
        direction="row"
        alignItems="flex-start"
      >
        <Stack
          sx={{ boxSizing: 'border-box' }}
          spacing={2}
          p={1}
          width="50%"
          display="inline-block"
        >
          {mainBasicResultShown ? (
            <BasicResultMenu
              prescId={selectedPresc.id}
              reportBasicResult={reportBasicResult}
              editBasicResult={editBasicResult}
              basicResult={selectedPresc.basic_result}
              isCheckboxDisabled={isCheckboxDisabled}
              getHasHighCheckPriority={getHasHighCheckPriority}
              displayItems={[
                ...patientMatchingDisplayItems,
                ...nonMatchingMainDisplayItems,
              ]}
              inTwoColumns={false}
            />
          ) : (
            <BasicResultMenu
              prescId={selectedPresc.id}
              reportBasicResult={reportBasicResult}
              editBasicResult={editBasicResult}
              basicResult={selectedPresc.basic_result}
              isCheckboxDisabled={isCheckboxDisabled}
              getHasHighCheckPriority={getHasHighCheckPriority}
              displayItems={[...nonMatchingSubDisplayItems]}
              inTwoColumns={false}
            />
          )}
        </Stack>
        <Stack
          sx={{ boxSizing: 'border-box' }}
          p={1}
          width="50%"
          display="inline-block"
        >
          <QRImage
            prescription={selectedPresc}
            isLoadingNewQr={isCheckboxDisabled}
            openModal={openQrImageModal}
          />
          {CsvButton}
        </Stack>
      </Stack>
    </Box>
  )
}
