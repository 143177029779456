import { FC } from 'react'
import { AIChatPage } from './chat'
import { SupportChatPage } from './supportChat'

type LegacyRoute = {
  path: string
  component: FC
}
export const LegacyRoutes: LegacyRoute[] = [
  { path: '/legacy/ai_chat', component: AIChatPage },
  { path: '/legacy/support_chat', component: SupportChatPage },
]
