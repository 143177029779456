import React, { CSSProperties, FC, ReactNode } from 'react'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormLabel from '@mui/material/FormLabel'
import { Box, Stack, Typography, useTheme } from '@mui/material'

type Item = {
  label: string
  value: string
  isSelected: boolean
}

type CheckboxesProps = {
  onChange: (value: string) => void
  checkboxList: Item[]
  label: string
  isDisabled: boolean
  hasHighCheckPriority: boolean
  maxWidth: number
  isReported: boolean
}

export const ReportCheckboxes: FC<CheckboxesProps> = ({
  onChange,
  checkboxList,
  label,
  isDisabled,
  hasHighCheckPriority,
  maxWidth,
  isReported,
}) => {
  const theme = useTheme()
  return (
    <Box
      pl={1}
      sx={{
        maxWidth: maxWidth,
      }}
      color={theme.palette.primary.dark}
    >
      <FormControl sx={{ width: '100%' }}>
        <FormLabel
          sx={{
            '&.Mui-focused': {
              color: isReported
                ? theme.palette.primary.dark
                : theme.palette.secondary.dark,
            },
            color: hasHighCheckPriority
              ? theme.palette.warning.main
              : isReported
              ? theme.palette.primary.dark
              : theme.palette.secondary.dark,
            height: 12,
            mb: 1.5,
            fontWeight: isReported ? 'bold' : 'initial',
          }}
          component="legend"
          color={hasHighCheckPriority ? 'warning' : 'secondary'}
        >
          <Stack direction="row" spacing={1}>
            <Typography fontSize={14}>{label}</Typography>
            {isReported && <Typography>✔️</Typography>}
          </Stack>
        </FormLabel>
        <FormGroup row>
          {checkboxList.map((item) => (
            <FormControlLabel
              sx={{ color: '#000', width: '47%' }}
              key={item.value}
              control={
                <Checkbox
                  sx={{ height: 24 }}
                  size="small"
                  color="info"
                  checked={item.isSelected}
                  onChange={(e) => onChange(e.target.value)}
                  value={item.value}
                  disabled={isDisabled}
                />
              }
              label={item.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    </Box>
  )
}

type CheckboxProps = {
  label: string
  icon?: ReactNode
  isChecked: boolean
  onChange: () => void
}

export const ReportCheckBox: FC<CheckboxProps> = ({
  label,
  icon,
  isChecked,
  onChange,
}) => {
  const theme = useTheme()

  return (
    <FormControlLabel
      control={
        <Checkbox
          sx={{ height: 24 }}
          size="small"
          color="info"
          checked={isChecked}
          onChange={() => onChange()}
        />
      }
      label={
        <Stack direction="row" alignItems="center" spacing={1}>
          <Stack direction="row" alignItems="center">
            <Typography
              sx={{
                fontSize: 16,
                color: theme.palette.text.primary,
                fontWeight: isChecked ? 'bold' : 'normal',
              }}
            >
              {label}
            </Typography>
            {icon && <>{icon}</>}
          </Stack>
        </Stack>
      }
      sx={{ color: '#000', width: '100%' }}
    />
  )
}
