import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { FC } from 'react'
import {
  getAiChangelogs,
  getFeatureRequests,
  getStatistics,
} from '../../apis/dashboard'
import { Statistics } from '../../types/models/Dashboard'
import { FeatureRequestModalBody } from '../../components/templates/FeatureRequestModalBody'
import { useCompactModal } from '../../hooks/useCompactModal'
import './dashboard.css'
import { saveAs } from 'file-saver'
import { DashboardGraph } from '../../components/molecules/DashboardGraph'
import { DashboardSquarePanelGroup } from '../../components/molecules/DashboardSquarePanel'
import { DashboardVennPanelGroup } from '../../components/molecules/DashboardVennPanel'
import { useLoading } from '../../hooks/useLoading'
import useReactRouter from 'use-react-router'

export const Dashboard: FC = () => {
  const [statistics, setStatistics] = React.useState<Statistics | null>(null)
  const { LoadingOverlay, setIsLoading } = useLoading()
  React.useEffect(() => {
    getStatistics().then((data) => setStatistics(data.data))
  }, [])

  const [
    featureRequests,
    setFeatureRequests,
  ] = React.useState<JSX.Element | null>(null)

  const onSubmitFeatureRequest = React.useCallback(() => {
    closeModal()
    loadFeatreRequests()
  }, [])

  const { openModal, closeModal, renderModal } = useCompactModal(
    <FeatureRequestModalBody onSubmit={onSubmitFeatureRequest} />,
    'center'
  )

  const loadFeatreRequests = React.useCallback(() => {
    getFeatureRequests().then((data) => {
      setFeatureRequests(
        <ul>
          {data.data
            .sort(
              (a, b) =>
                new Date(b.updated_at).getTime() -
                new Date(a.updated_at).getTime()
            )
            .map((item, i) => (
              <li key={i} style={{ whiteSpace: 'pre-line' }}>
                <b>{item.request}</b>
                {item.reply && (
                  <ul>
                    <li className="reply" style={{ whiteSpace: 'pre-line' }}>
                      {item.reply}
                    </li>
                  </ul>
                )}
              </li>
            ))}
        </ul>
      )
    })
  }, [])

  React.useEffect(() => {
    loadFeatreRequests()
  }, [loadFeatreRequests])

  const dataByDay = statistics?.event_counts_by_day.map((item, i) => ({
    label: i + 1,
    eventCount: item,
    busynessScore: statistics?.busyness_scores_by_day[i],
    errorCountDict: statistics?.error_count_dicts_by_day[i],
  }))

  const dataByHour = statistics?.event_counts_by_hour
    .map((item, i) => ({
      label: i,
      eventCount: item,
      busynessScore: statistics?.busyness_scores_by_hour[i],
    }))
    .slice(8) // 八時以降

  const saveAsCsv = (statistics: Statistics) => {
    const date = new Date(statistics.date)
    const year = date.getFullYear()
    const month1 = date.getMonth() + 1
    const day = date.getDate()

    saveAs(
      new Blob(
        [
          statistics.event_counts_by_day
            .map((value, i) => `${year}-${month1}-${i + 1},${value}`)
            .join('\r\n'),
        ],
        { type: 'text/csv' }
      ),
      `日別統計-${year}-${month1}-${day}.csv`
    )
    saveAs(
      new Blob(
        [
          statistics.event_counts_by_hour
            .map((value, i) => `${i}:00,${value}`)
            .join('\r\n'),
        ],
        { type: 'text/csv' }
      ),
      `時間別統計-${year}-${month1}-${day}.csv`
    )
    saveAs(
      new Blob(
        [
          `累計患者,${statistics.patient_count}\r\n`,
          `累計処方箋,${statistics.image_count}\r\n`,
          `累計調剤,${statistics.rp_count}\r\n`,
          `累計薬剤,${statistics.med_count}\r\n`,
          `新患,${Math.floor(
            statistics.new_patient_rate * statistics.patient_count
          )}\r\n`,
          `複数科受付,${statistics.patient_count_with_multiple_department}\r\n`,
          `多薬剤処方(薬剤4個以上),${statistics.event_count_with_many_meds}\r\n`,
          `長文コメント(10文字以上),${statistics.rp_count_with_long_comment}\r\n`,
          `分包/力価単位変換,${statistics.unit_converted_med_count}\r\n`,
        ],
        { type: 'text/csv' }
      ),
      `使用状況-負荷の高い入力-${year}-${month1}-${day}.csv`
    )
  }

  const { history } = useReactRouter()

  return (
    <Grid container spacing={6} sx={{ paddingX: 2 }}>
      {/* {LoadingOverlay} */}
      <Grid
        item
        xs={6}
        sx={{
          height: '100vh',
        }}
      >
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '1vh' }}>
          <Box
            style={{
              flex: 1,
              paddingTop: '2.5vh',
            }}
          >
            <DashboardSquarePanelGroup
              title="使用状況 (直近一ヶ月)"
              patient_count={statistics?.patient_count ?? 0}
              image_count={statistics?.image_count ?? 0}
              rp_count={statistics?.rp_count ?? 0}
              med_count={statistics?.med_count ?? 0}
            />
          </Box>
          <Box
            style={{
              flex: 1,
              paddingTop: '2.5vh',
            }}
          >
            <DashboardVennPanelGroup
              title="負荷の高い入力(直近一ヶ月)"
              patient_count={statistics?.patient_count ?? 0}
              new_patient_rate={statistics?.new_patient_rate ?? 0}
              patient_count_with_multiple_department={
                statistics?.patient_count_with_multiple_department ?? 0
              }
              event_count_with_many_meds={
                statistics?.event_count_with_many_meds ?? 0
              }
              rp_count={statistics?.rp_count ?? 0}
              rp_count_with_long_comment={
                statistics?.rp_count_with_long_comment ?? 0
              }
              med_count={statistics?.med_count ?? 0}
              unit_converted_med_count={
                statistics?.unit_converted_med_count ?? 0
              }
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} sx={{ height: '100vh' }}>
        <div
          style={{
            height: '45vh',
            paddingTop: '2.5vh',
            paddingBottom: '5vh',
          }}
        >
          {dataByDay && (
            <DashboardGraph
              data={dataByDay}
              title={`日別患者数&忙しさスコア&報告数（${
                new Date(statistics!.date).getMonth() + 1
              }月）`}
              xTitle="日付"
            />
          )}
        </div>
        <div style={{ height: '45vh' }}>
          <Stack
            direction="column"
            sx={{
              height: '100%',
            }}
          >
            {dataByHour && (
              <DashboardGraph
                data={dataByHour}
                title="時間別患者数&忙しさスコア（直近一ヶ月）"
                xTitle="時間"
              />
            )}
            <Grid container justifyContent="flex-end" sx={{ paddingTop: 1 }}>
              {statistics && (
                <Button
                  variant="outlined"
                  color="success"
                  onClick={() => {
                    saveAsCsv(statistics)
                  }}
                >
                  CSV出力
                </Button>
              )}
            </Grid>
          </Stack>
        </div>
      </Grid>

      <Button
        variant="contained"
        sx={{ position: 'fixed', left: 20, bottom: 20 }}
        onClick={() => history.push('/')}
      >
        処方画面へ戻る
      </Button>
      {renderModal()}
    </Grid>
  )
}
