import { FC } from 'react'
import { PatientMatching } from './patientMatching'
import { DoctorMatching } from './doctorMatching'
import { YouhouMatching } from './youhouMatching'

type helpRoute = {
  path: string
  component: FC
}

export const helpRoutes: helpRoute[] = [
  { path: '/help/patient_matching', component: PatientMatching },
  { path: '/help/doctor_matching', component: DoctorMatching },
  { path: '/help/youhou_matching', component: YouhouMatching },
]
