import React, { FC } from 'react'
import Markdown from 'markdown-to-jsx'
import {
  Box,
  Button,
  Container,
  Typography,
  useTheme,
  useScrollTrigger,
  Slide,
} from '@mui/material'
import { useHistory } from 'react-router-dom'

// ここではサンプルとして、固定のMarkdown文字列を用意
// 実際には、APIやファイルから取得したMarkdownを流す形にしてもOKです。
const markdownText = `
## Step1: まもる君の読み取り結果確認

●「まもる君アプリ(今開いているもの)上の「患者一致情報タブ」の項目を確認ください

●「もし読み取りに誤りがあった場合はまもる君にご報告ください ⇒ こちらで、まもる君の学習が促進され、本誤りが軽減されることが見込まれます。

## Step2: まもる君の読み取りは正解していた場合

●「患者一致情報」の項目が全て一致していた場合は、レセコン側の「患者一致紐づけ設定」に何かズレがある可能性があります

●お手数ですがレセコンベンダー様にお問い合わせくださいませ


---

今後もFocus on the Userに基づき、薬局の皆様からのお声に耳を傾け、より質の高いサービス開発・提供を目指してまいります。
些細なことでも結構ですので、ご質問・ご要望がございましたら、どうぞお気軽に＜support@medilab.co.jp＞までお寄せください。
`

export const PatientMatching: FC = () => {
  const theme = useTheme()
  const history = useHistory()

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  })

  return (
    <>
      <Box
        sx={{
          minHeight: '100vh',
          backgroundColor: theme.palette.grey[100],
          py: 4,
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h4" component="h1" gutterBottom>
            再来患者が新患と認識される
          </Typography>
          {/* Markdown の表示 */}
          <Markdown>{markdownText}</Markdown>
        </Container>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: 24,
          left: 40,
          zIndex: 1000,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => history.push('/')}
        >
          {'処方画面へ戻る'}
        </Button>
      </Box>
    </>
  )
}
