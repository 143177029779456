import { Box, ButtonBase, Stack, Typography, useTheme } from '@mui/material'
import React, { FC, memo, NamedExoticComponent, useState } from 'react'
import KeyboardDoubleArrowDownRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowDownRounded'
import { HighlightInputBox } from '../molecules/HighlightInputBox'
import { INPUTBOX_MAX_WIDTH } from '../../constants/const'
import { SelectBox } from '../molecules/SelectBox'
import { getHasHighCheckPriority } from '../../helpers/getHasHighCheckPriority'
import { toCircledNum } from '../../helpers/toCircledNum'
import { Prescription, RPUnit, Medication } from '../../types/models/Presc'
import { ReportCheckBox } from '../molecules/Checkbox'
import { keyframes } from '@emotion/react'

type ColumnBasedProps = {
  prescription: Prescription
  reportRPUnit: (
    rp_num: number,
    key: keyof RPUnit,
    is_reported: boolean
  ) => void
  reportMedication: (
    rp_num: number,
    rp_num_sub: number,
    key: keyof Medication,
    is_reported: boolean
  ) => void
}

// 新UI
export const ColumnBasedMedicineResult: NamedExoticComponent<ColumnBasedProps> = memo(
  ({ prescription, reportRPUnit, reportMedication }) => {
    const theme = useTheme()
    return (
      <>
        {prescription.med_result.map((rp, rpIndex) => (
          <div
            key={`${prescription.ocr_request_event_id}-${rp['RP番号'].selected_option}`}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                boxSizing: 'border-box',
                border: '2px solid #fff',
                bgcolor: '#fff',
                color: theme.palette.secondary.dark,
                borderRadius: '8px',
                overflowWrap: 'break-word',
                mt: 2,
                pb: 4,
                overflow: 'hidden',
                pl: 2,
                pt: 2,
              }}
            >
              <Stack width="100%">
                <Stack direction="row" justifyContent="space-between">
                  <Box>
                    <Typography mb={1}>
                      <h3 style={{ margin: 0 }}>
                        {'処方内容' +
                          toCircledNum(Number(rp['RP番号'].selected_option))}
                      </h3>
                    </Typography>
                  </Box>
                  <Box pr={2}>
                    <ReportCheckBox
                      label={'医薬品漏れ'}
                      icon={<KeyboardDoubleArrowDownRoundedIcon />}
                      isChecked={rp['医薬品取得'].is_reported}
                      onChange={() =>
                        reportRPUnit(
                          rpIndex,
                          '医薬品取得',
                          !rp['医薬品取得'].is_reported
                        )
                      }
                    />
                  </Box>
                </Stack>
                <Stack pr={2} mt={1}>
                  {rp.med_result.map((med, medIndex) => (
                    <div
                      key={`${prescription.ocr_request_event_id}-${rp['RP番号'].selected_option}-${med['薬品名称'].selected_option}`}
                    >
                      <Stack direction="row">
                        <Box mr={3} width="70%">
                          <HighlightInputBox
                            onClick={() =>
                              reportMedication(
                                rpIndex,
                                medIndex,
                                '薬品名称',
                                !med['薬品名称'].is_reported
                              )
                            }
                            label={'医薬品名'}
                            isHighlighted={med['薬品名称'].is_reported}
                            value={med['薬品名称'].selected_option}
                            maxWidth={INPUTBOX_MAX_WIDTH.medicine}
                            hasHighCheckPriority={getHasHighCheckPriority(
                              med['薬品名称'].user_check_priority
                            )}
                            isShrinkForbidden={medIndex !== 0}
                            isTall={true}
                          />
                        </Box>
                        <Box width="120px">
                          <HighlightInputBox
                            onClick={() =>
                              reportMedication(
                                rpIndex,
                                medIndex,
                                '用量',
                                !med['用量'].is_reported
                              )
                            }
                            label={'用量'}
                            isHighlighted={med['用量'].is_reported}
                            value={
                              med['用量'].selected_option +
                              med['単位名'].selected_option
                            }
                            maxWidth={INPUTBOX_MAX_WIDTH.medicine}
                            hasHighCheckPriority={getHasHighCheckPriority(
                              Math.max(
                                med['用量'].user_check_priority,
                                med['単位名'].user_check_priority
                              )
                            )}
                            isShrinkForbidden={medIndex !== 0}
                            isTall={true}
                          />
                        </Box>
                      </Stack>
                    </div>
                  ))}
                </Stack>
                <Stack pr={2}>
                  <Box>
                    <hr
                      style={{
                        border: 'none',
                        backgroundColor: '#F4F4F4',
                        width: '100%',
                        height: '2px',
                      }}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" pr={2} pt={2}>
                  <Box width="70%" mr={3}>
                    <HighlightInputBox
                      onClick={() =>
                        reportRPUnit(
                          rpIndex,
                          '用法名称',
                          !rp['用法名称'].is_reported
                        )
                      }
                      label={'用法'}
                      isHighlighted={rp['用法名称'].is_reported}
                      value={rp['用法名称'].selected_option}
                      maxWidth={INPUTBOX_MAX_WIDTH.usage}
                      hasHighCheckPriority={getHasHighCheckPriority(
                        rp['用法名称'].user_check_priority
                      )}
                      isTall={true}
                    />
                  </Box>
                  <Box width="120px">
                    <HighlightInputBox
                      onClick={() =>
                        reportRPUnit(
                          rpIndex,
                          '調剤数量',
                          !rp['調剤数量'].is_reported
                        )
                      }
                      label={'調剤数量'}
                      isHighlighted={rp['調剤数量'].is_reported}
                      value={rp['調剤数量'].selected_option}
                      maxWidth={INPUTBOX_MAX_WIDTH.usage}
                      hasHighCheckPriority={getHasHighCheckPriority(
                        rp['調剤数量'].user_check_priority
                      )}
                      isTall={true}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" pr={2} pt={2}>
                  <Box width="70%" mr={3}>
                    <HighlightInputBox
                      onClick={() =>
                        reportRPUnit(
                          rpIndex,
                          'コメント',
                          !rp['コメント'].is_reported
                        )
                      }
                      label={'コメント'}
                      isHighlighted={rp['コメント'].is_reported}
                      value={rp['コメント'].selected_option || 'コメントなし'}
                      maxWidth={INPUTBOX_MAX_WIDTH.usage}
                      hasHighCheckPriority={getHasHighCheckPriority(
                        rp['コメント'].user_check_priority
                      )}
                      isTall={true}
                    />
                  </Box>
                </Stack>
              </Stack>
            </Box>
          </div>
        ))}
      </>
    )
  }
)

type RPBasedProps = {
  prescription: Prescription
  reportRPIndex: (rp_num: number, is_reported: boolean) => void
}

// 旧UI
export const RPBasedMedicineResult: NamedExoticComponent<RPBasedProps> = memo(
  ({ reportRPIndex, prescription }) => {
    const theme = useTheme()
    return (
      <>
        {prescription.med_result.map((rp, rpIndex) => (
          <div
            key={`${prescription.ocr_request_event_id}-${rp['RP番号'].selected_option}`}
          >
            <ButtonBase
              sx={{
                display: 'flex',
                width: '100%',
                boxSizing: 'border-box',
                border: rp.is_reported ? '2px solid' : '2px solid #fff',
                bgcolor: '#fff',
                color: rp.is_reported
                  ? theme.palette.primary.dark
                  : theme.palette.secondary.dark,
                borderRadius: '8px',
                overflowWrap: 'break-word',
                mt: 2,
                pb: 4,
                overflow: 'hidden',
                pl: 2,
                pt: 2,
              }}
              onClick={() => reportRPIndex(rpIndex, !rp.is_reported)}
            >
              <Stack width="100%">
                <Stack direction="row" justifyContent="space-between">
                  <Box>
                    <Typography mb={1}>
                      <h3 style={{ margin: 0 }}>
                        {'処方内容' +
                          toCircledNum(Number(rp['RP番号'].selected_option))}
                      </h3>
                    </Typography>
                  </Box>
                  {rp.is_reported && (
                    <Box pr={2}>
                      <Typography>✔️</Typography>
                    </Box>
                  )}
                </Stack>
                {rp.med_result.map((med, medIndex) => (
                  <div
                    key={`${prescription.ocr_request_event_id}-${rp['RP番号'].selected_option}-${med['薬品名称'].selected_option}`}
                  >
                    <Stack direction="row" pr={2}>
                      <Box mr={3} width="70%">
                        <SelectBox
                          onChangeChoice={(value: string) => {}}
                          choices={med['薬品名称'].options}
                          selectedChoice={med['薬品名称'].selected_option}
                          maxWidth={INPUTBOX_MAX_WIDTH.medicine}
                          hasHighCheckPriority={getHasHighCheckPriority(
                            med['薬品名称'].user_check_priority
                          )}
                        />
                      </Box>
                      <Box width="120px">
                        <SelectBox
                          onChangeChoice={(value: string) => {}}
                          choices={med['用量'].options.map(
                            (dose) => dose + med['単位名'].selected_option
                          )}
                          selectedChoice={
                            med['用量'].selected_option +
                            med['単位名'].selected_option
                          }
                          hasHighCheckPriority={getHasHighCheckPriority(
                            Math.max(
                              med['用量'].user_check_priority,
                              med['単位名'].user_check_priority
                            )
                          )}
                          maxWidth={INPUTBOX_MAX_WIDTH.medicine}
                        />
                      </Box>
                    </Stack>
                  </div>
                ))}
                <Stack pr={2}>
                  <Box>
                    <hr
                      style={{
                        border: 'none',
                        backgroundColor: '#F4F4F4',
                        width: '100%',
                        height: '2px',
                      }}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" pr={2}>
                  <Box width="70%" mr={3}>
                    <SelectBox
                      onChangeChoice={(value: string) => {}}
                      choices={rp['用法名称'].options}
                      selectedChoice={rp['用法名称'].selected_option}
                      maxWidth={INPUTBOX_MAX_WIDTH.usage}
                      hasHighCheckPriority={getHasHighCheckPriority(
                        rp['用法名称'].user_check_priority
                      )}
                    />
                  </Box>
                  <Box width="120px">
                    <SelectBox
                      onChangeChoice={(value: string) => {}}
                      choices={rp['調剤数量'].options}
                      selectedChoice={rp['調剤数量'].selected_option}
                      maxWidth={INPUTBOX_MAX_WIDTH.usage}
                      hasHighCheckPriority={getHasHighCheckPriority(
                        rp['調剤数量'].user_check_priority
                      )}
                    />
                  </Box>
                </Stack>
              </Stack>
            </ButtonBase>
          </div>
        ))}
      </>
    )
  }
)

ColumnBasedMedicineResult.displayName = 'MedicineResult'
RPBasedMedicineResult.displayName = 'MedicineResult'
