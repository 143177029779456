import { Link } from '@mui/icons-material'
import { Typography } from '@mui/material'
import React, { FC } from 'react'
import { localStorageWrapper } from '../../helpers/localStorage'
import { useGetAppType } from '../../hooks/useGetAppType'

export const Copyright: FC = () => {
  const appType = useGetAppType()
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {appType === 'Medilab' || appType === 'Medilab_LATEST'
        ? `Copyright © Medilab. ${new Date().getFullYear()}. ver.3.1.2`
        : `© ${new Date().getFullYear()} Transaction Media Networks Inc. All Rights Reserved`}
    </Typography>
  )
}
