import client from './client'
import { AxiosPromise } from 'axios'
import { BasicResult, RPUnit, Medication } from '../types/models/Presc'

export type ReportBasicResultParams = {
  prescId: string
  key: keyof BasicResult
  isReported: boolean
}

export type ReportRPUnitParams = {
  prescId: string
  rpNum: number
  key: keyof RPUnit
  isReported: boolean
}

export type ReportMedicationParams = {
  prescId: string
  rpNum: number
  rpNumSub: number
  key: keyof Medication
  isReported: boolean
}

export type ReportRPIndexParams = {
  prescId: string
  rpNum: number
  isReported: boolean
}

export type ReportMedShortageParams = {
  prescId: string
  ocrErrorLabel: string
}

export const postBasicResultReport = ({
  prescId,
  key,
  isReported,
}: ReportBasicResultParams): AxiosPromise => {
  const params = { isReported }
  return client.post(`prescriptions/${prescId}/basic_results/${key}/`, params)
}

export const postRPUnitReport = ({
  prescId,
  rpNum,
  key,
  isReported,
}: ReportRPUnitParams): AxiosPromise => {
  const params = { isReported }
  return client.post(
    `prescriptions/${prescId}/med_results/${rpNum}/${key}/`,
    params
  )
}

export const postMedicationReport = ({
  prescId,
  rpNum,
  rpNumSub,
  key,
  isReported,
}: ReportMedicationParams): AxiosPromise => {
  const params = { isReported }
  return client.post(
    `prescriptions/${prescId}/med_results/${rpNum}/${rpNumSub}/${key}/`,
    params
  )
}

export const postRPIndexReport = ({
  prescId,
  rpNum,
  isReported,
}: ReportRPIndexParams): AxiosPromise => {
  const params = { isReported }
  return client.post(`prescriptions/${prescId}/med_results/${rpNum}/`, params)
}

export const postMedResultShortage = ({
  prescId,
  ocrErrorLabel,
}: ReportMedShortageParams): AxiosPromise => {
  return client.post(`prescriptions/${prescId}/med_results/ocr_error/`, {
    label: ocrErrorLabel,
  })
}
